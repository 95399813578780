import { useSearchParams } from "react-router-dom"
import style from "../css/Video.module.css"
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import SEOMetaTag from "../Components/SEOMetaTag";
import Portal from '../Components/Portal';
import AdminModal from '../AdminComponents/AdminModal';
import logo from "../image/studio/logo.svg"
import arrow from "../image/studio/arrow.svg"

export default function Video({isEn, admin, setAdmin, showAdmin, setShowAdmin, adminField, setAdminField, render, setRender}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const [padding, setPadding] = useState([20, 20])
    const circleRef = useRef();
    const hrRef = useRef();
    const contentRef = useRef();
    // const columnData = ["overview", "producer", "work"];
    // const columnKorData = ["Studio The Black", "프로듀서", "작품"];
    // const columnEnData = ["Studio The Black", "Producer", "Work"];
    const columnData = ["producer", "work"];
    const columnKorData = ["프로듀서", "작품"];
    const columnEnData = ["Producer", "Work"];
    const [getData, setData] = useState();
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    const [autoHeight, setAutoHeight] = useState([0, 0, 0]);

    function changeColumn(column) {
        searchParams.set("column", column)
        setSearchParams(searchParams)
    }

    function getPadding(dataCount, size) {
        var _padding = 20;
        var itemWidth = 0;

        if(document.body.clientWidth > 1317) {
            return 0;
        }
        if(size === "s") {
            itemWidth = 152;
        } else if(size === "b") {
            itemWidth = 320;
        }

        if(size === "b" && dataCount <= 1 && document.body.clientWidth < 676) {
            _padding = (document.body.clientWidth - itemWidth) / 2;
        } else {
            for(var i=1; i<=dataCount; i++) {
                if((itemWidth * i + 16 * (i - 1)) > document.body.clientWidth) {
                    _padding = (document.body.clientWidth - (itemWidth * (i - 1) + 16 * (i - 2))) / 2;
                    break
                }
            }
        }
        return _padding;
    }

    async function apiCall() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/video');
            setData(response.data.data)
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    function openItem(e) {
        if((e.target.parentNode.parentNode.className).indexOf('open') === -1) {
            e.target.parentNode.parentNode.className = style.producerItem + " " + style.open
            setTimeout(() => {
                contentRef.current.parentNode.style.height = contentRef.current.childNodes[1].clientHeight + "px";
                if(document.body.clientWidth > 1300) {
                    var h = 0;
                    for(var i=0;i<e.target.parentNode.parentNode.childNodes[1].childNodes.length;i++) {
                        h += e.target.parentNode.parentNode.childNodes[1].childNodes[i].clientHeight;
                    }
                    if(h % 88 !== 0) {
                        e.target.parentNode.parentNode.childNodes[1].style.height = (h + 88) / 2 + "px"
                    } else {
                        e.target.parentNode.parentNode.childNodes[1].style.height = h / 2 + "px";
                    }
                }
              }, 100)
        } else {
            e.target.parentNode.parentNode.className = style.producerItem
            e.target.parentNode.parentNode.childNodes[1].style.height = "fit-content"
            setTimeout(() => {
                contentRef.current.parentNode.style.height = contentRef.current.childNodes[1].clientHeight + "px";
            }, 200)
        }
    }

    useEffect(() => {
        setColumn(searchParams.get("column"));
        t()
        switch (columnData.indexOf(searchParams.get("column"))) {
            case 0:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
            case 1:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-100%)";
                contentRef.current.parentNode.style.height = autoHeight[1] + "px";
                return
            case 2:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.clientWidth - 16 + "px";
                } else {
                    circleRef.current.style.left = hrRef.current.clientWidth + 12 + "px";
                }
                contentRef.current.style.transform = "translateX(-200%)";
                contentRef.current.parentNode.style.height = autoHeight[2] + "px";
                return
            default:
                setColumn("prop");
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
        }
    }, [searchParams, screenWidth, autoHeight])

    useEffect(() => {
        if(getData === undefined) {
            setPadding([0, 0]);
        } else {
            padding[0] = getPadding(getData.work.length, 's')
            setPadding([...padding])
            window.addEventListener('resize', () => {
                setScreenWidth(document.body.clientWidth);
                if(document.body.clientWidth > 1317) {
                    setPadding([0, 0]);
                } else {
                    padding[0] = getPadding(getData.work.length, 's')
                    setPadding([...padding])
                }
            });
            return () => {
                window.removeEventListener('resize', () => {
                    setScreenWidth(document.body.clientWidth);
                    if(document.body.clientWidth > 1317) {
                        setPadding([0, 0]);
                    } else {
                        padding[0] = getPadding(getData.work.length, 's')
                        setPadding([...padding])
                    }
                });
            }
        }
    }, [getData]);

    useEffect(() => {
        apiCall()
    }, [])

    function t(e) {
        if(JSON.stringify(autoHeight) !== JSON.stringify([
            contentRef.current.childNodes[0].clientHeight,
            contentRef.current.childNodes[1].clientHeight,
            contentRef.current.childNodes[2].clientHeight
        ])) {
            setAutoHeight([
                contentRef.current.childNodes[0].clientHeight,
                contentRef.current.childNodes[1].clientHeight,
                contentRef.current.childNodes[2].clientHeight
            ]);
        }
    }

    return (
        <div className={style.wrap} onLoad={t}>
            <SEOMetaTag title="미디어사업부" description="한주에이알티 주식회사 미디어사업부 페이지" url="https://hanjooart.com/video"></SEOMetaTag>
            <div className={style.topContainer}>
                <span>{isEn ? "Media Business Dept." : "미디어사업부"}</span>
                <div ref={hrRef} className={style.columnContainer}>
                    {
                        columnData.map((data, idx) => (
                            <span key={idx} onClick={() => changeColumn(data)} className={data === column ? `${style.columnContainerItem} ${style.selected}` : style.columnContainerItem}>{isEn ? columnEnData[idx] : columnKorData[idx]}</span>
                        ))
                    }
                </div>
                <div ref={circleRef} className={style.hrCircle}></div>
            </div>
            <div className={style.bottomContainer}>
                <div ref={contentRef} className={style.bottomContainerWrap}>
                    {/* <div key={Math.random()} className={style.contentContainer}>
                        <div className={style.introContainer}>
                            <img src={logo} alt="logo"></img>
                            <span>
                                {
                                    isEn ?
                                    "Studio The Black aims to become a unique and strong entity that cannot be covered by any color by drawing talent from various fields for realistic visual beauty such as props, special prop design, and interior design."
                                    :
                                    "스튜디오 더 블랙은 영화 및 드라마를 기획 제작하여 극본을 스토리에 맞게 이미지화하여 영상 속의 소품, 특수 소품 디자인, 인테리어 등 현실감 있는 영상미를 위해 다양한 분야의 인재들을 영입하여 대한민국 미디어 시장을 본사만의 색깔로 칠해 어떤 색으로도 덮을 수 없는 독보적이며 강인한 존재가 되자는 목표를 갖고 임하고 있습니다."
                                }
                            </span>
                        </div>
                        <div className={style.boxContainer}>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Company</span>
                                <span className={style.overviewContent}>{isEn ? "Studio The Black Co., Ltd." : "(주)스튜디오더블랙"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>CEO</span>
                                <span className={style.overviewContent}>{isEn ? "Se-Hoon Baek" : "백세훈"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Business</span>
                                <span className={style.overviewContent}>{isEn ? "the production of movies and videos" : "영화,비디오물 제작"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Since</span>
                                <span className={style.overviewContent}>{isEn ? "January 20th, 2020" : "2020년 1월 20일"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Address</span>
                                <span className={style.overviewContent}>{isEn ? "39-23, Buldang-ro, Yangchon-eup, Gimpo-si, Gyeonggi-do" : "경기 김포시 양촌읍 불당로 39-23 (흥신리) 스튜디오더블랙"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Contact</span>
                                <span className={style.overviewContent}>{"Tel: 031-975-6068"}</span>
                            </div>
                        </div>
                    </div> */}
                    <div key={Math.random()} className={style.contentContainer}>
                        {
                            getData !== undefined &&
                            getData.producer.map((data) => (
                                <div key={data.id} className={style.producerItem}>
                                    <div className={style.itemContainer}>
                                        <span>{isEn ? data.e_position : data.position}</span>
                                        <span>{isEn ? data.e_name : data.name}</span>
                                        <img src={arrow} alt="arrow" onClick={(e) => openItem(e)}></img>
                                    </div>
                                    <div className={style.listConatiner}>
                                        {
                                            isEn ?
                                            data.e_works.split('/').map((work, idx) => (
                                                <span key={idx}>{work}</span>
                                            ))
                                            :
                                            data.works.split('/').map((work, idx) => (
                                                <span key={idx}>{work}</span>
                                            ))
                                        }
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <div key={Math.random()} className={style.contentContainer} style={{padding: "0 " + padding[0] + "px"}}>
                        {
                            getData !== undefined &&
                            getData.work.map((data) => (
                                <div key={data.id} className={style.workItem}>
                                    <img src={data.img} alt="work-img"></img>
                                    <span>{data.year} {data.broadcaster}<br></br>{isEn ? data.e_theme : data.theme}</span>
                                </div>
                            ))
                        }
                    </div>
                    <div key={Math.random()} className={style.contentContainer}>
                        {/* <div className={style.introContainer}>
                            <img src={logo} alt="logo"></img>
                            <span>
                                {
                                    isEn ?
                                    "Studio The Black aims to become a unique and strong entity that cannot be covered by any color by drawing talent from various fields for realistic visual beauty such as props, special prop design, and interior design."
                                    :
                                    "스튜디오 더 블랙은 영화 및 드라마를 기획 제작하여 극본을 스토리에 맞게 이미지화하여 영상 속의 소품, 특수 소품 디자인, 인테리어 등 현실감 있는 영상미를 위해 다양한 분야의 인재들을 영입하여 대한민국 미디어 시장을 본사만의 색깔로 칠해 어떤 색으로도 덮을 수 없는 독보적이며 강인한 존재가 되자는 목표를 갖고 임하고 있습니다."
                                }
                            </span>
                        </div>
                        <div className={style.boxContainer}>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Company</span>
                                <span className={style.overviewContent}>{isEn ? "Studio The Black Co., Ltd." : "(주)스튜디오더블랙"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>CEO</span>
                                <span className={style.overviewContent}>{isEn ? "Se-Hoon Baek" : "백세훈"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Business</span>
                                <span className={style.overviewContent}>{isEn ? "the production of movies and videos" : "영화,비디오물 제작"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Since</span>
                                <span className={style.overviewContent}>{isEn ? "January 20th, 2020" : "2020년 1월 20일"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Address</span>
                                <span className={style.overviewContent}>{isEn ? "39-23, Buldang-ro, Yangchon-eup, Gimpo-si, Gyeonggi-do" : "경기 김포시 양촌읍 불당로 39-23 (흥신리) 스튜디오더블랙"}</span>
                            </div>
                            <div className={style.overviewItem}>
                                <span className={style.overviewTheme}>Contact</span>
                                <span className={style.overviewContent}>{"Tel: 031-975-6068"}</span>
                            </div>
                        </div> */}
                    </div>          
                </div>
            </div>
            {
                admin &&
                column !== "overview" &&
                <div className={style.adminContainer}>
                    <button className={style.adminBtn} onClick={() => {setShowAdmin(true); setAdminField('video')}}>{columnKorData[columnData.indexOf(column)]} 관리</button>
                </div>
            }
            <Portal>
                {
                    showAdmin &&
                    <AdminModal setRender={ setRender } admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField}></AdminModal>
                }
            </Portal>
        </div>
    )
}