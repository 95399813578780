import { useSearchParams } from "react-router-dom"
import style from "../css/Enter.module.css"
import { useEffect, useRef, useState } from "react";
import SEOMetaTag from "../Components/SEOMetaTag";

export default function Enter({isEn}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const circleRef = useRef();
    const hrRef = useRef();
    const contentRef = useRef();
    const columnData = ["enter"];
    const columnKorData = ["엔터사업부"];
    const columnEnData = ["Entertainment"];
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    const [autoHeight, setAutoHeight] = useState([0]);

    function changeColumn(column) {
        searchParams.set("column", column)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(document.body.clientWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(document.body.clientWidth);
            });
        }
    }, [])

    useEffect(() => {
        setColumn(searchParams.get("column"));
        t()
        switch (columnData.indexOf(searchParams.get("column"))) {
            case 0:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
            default:
                setColumn("enter");
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
        }
    }, [searchParams, screenWidth, autoHeight])

    function t(e) {
        if(JSON.stringify(autoHeight) !== JSON.stringify([
            contentRef.current.childNodes[0].clientHeight
        ])) {
            setAutoHeight([
                contentRef.current.childNodes[0].clientHeight
            ]);
        }
    }

    return(
        <div className={style.wrap} onLoad={t}>
            <SEOMetaTag title="엔터사업부" description="한주에이알티 주식회사 엔터사업부 페이지" url="https://hanjooart.com/enter"></SEOMetaTag>
            <div className={style.topContainer}>
                <span>{isEn ? "Entertainment Dept." : "엔터사업부"}</span>
                <div ref={hrRef} className={style.columnContainer}>
                    {
                        columnData.map((data, idx) => (
                            <span key={idx} onClick={() => changeColumn(data)} className={data === column ? `${style.columnContainerItem} ${style.selected}` : style.columnContainerItem}>{isEn ? columnEnData[idx] : columnKorData[idx]}</span>
                        ))
                    }
                </div>
                <div ref={circleRef} className={style.hrCircle}></div>
            </div>
            <div className={style.bottomContainer}>
                <div ref={contentRef} className={style.bottomContainerWrap}>
                    <div key={Math.random()} className={style.contentContainer}>
                        <div className={style.enterItem}>
                            <div className={style.emptyBox}>
                                <span>Comming Soon</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}