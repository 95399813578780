import { Link, NavLink } from "react-router-dom"
import style from "../css/Header.module.css"
import lang from "../image/component/lang.png"
import lang_pc from "../image/component/lang.svg"
import logo from "../image/component/header_logo.svg"
import logoE from "../image/component/header_logoE.svg"
import menu from "../image/component/header_menu.png"
import { useState, useEffect, useRef } from 'react';

export default function Header({isEn, setIsEn}) {
    const [subMenuOn, setSubMenuOn] = useState(false);
    const menuRef = useRef();
    function showMenu(h) {
        if(h === 0) {
            document.body.style.overflow = "auto";
        } else {
            document.body.style.overflow = "hidden";
        }
        menuRef.current.style.height = h;
    }

    return(
        <div className={style.wrapCon}>
            <div className={style.wrapPC} onMouseOver={() => setSubMenuOn(true)}>
                <div className={style.wrapContainer} >
                    <Link to="/">
                        <img src={`${isEn ? logoE : logo}`} alt="header-logo" id={style.headerLogo}></img>
                    </Link>
                    <div className={style.menuContainer}>
                    <NavLink to="/about?column=outline" className={ ({isActive}) => isActive ? style.active : ""} onClick={() => setSubMenuOn(false)}>
                            {isEn ? "About" : "회사소개"}
                        </NavLink>
                        <NavLink style={{ display: "none" }} to="/show?column=concert" className={ ({isActive}) => isActive ? style.active : "" } onClick={() => setSubMenuOn(false)}>
                            {isEn ? "Performance\nDept." : "공연사업부"}
                        </NavLink>
                        <NavLink to="/video?column=producer" className={ ({isActive}) => isActive ? style.active : "" } onClick={() => setSubMenuOn(false)}>
                            {isEn ? "Media Business\nDept." : "미디어사업부"}
                        </NavLink>
                        <NavLink to="/food?column=brand" className={ ({isActive}) => isActive ? style.active : "" } onClick={() => setSubMenuOn(false)}>
                            {isEn ? "Food Service\nDept." : "외식사업부"}
                        </NavLink>
                        <NavLink to="/enter?column=enter" className={ ({isActive}) => isActive ? style.active : "" } onClick={() => setSubMenuOn(false)}>
                            {isEn ? "Entertainment\nDept." : "엔터사업부"}
                        </NavLink>
                        <NavLink to="/sfsteel?column=eoutline" className={ ({isActive}) => isActive ? style.active : "" } onClick={() => setSubMenuOn(false)}>
                            {isEn ? "SF Steel" : "SF Steel"}
                        </NavLink>
                        <NavLink to="/pr?column=notice" className={ ({isActive}) => isActive ? style.active : "" } onClick={() => setSubMenuOn(false)}>
                            {isEn ? "PR" : "PR"}
                        </NavLink>
                        <button id={style.langBtn} onClick={() => setIsEn(!isEn)}>
                            {isEn ? "KO" : "EN"}
                            <img src={lang_pc} alt="lang-icon" id={style.langIcon}></img>
                        </button>
                    </div>
                </div>
                <div className={subMenuOn ? `${style.subWrapContainer} ${style.on}` : style.subWrapContainer} onMouseLeave={() => setSubMenuOn(false)}>
                    <div className={style.subMenuContainer}>
                        <div className={isEn ? style.esubMenuItem : style.subMenuItem}>
                            <NavLink to="/about?column=outline" onClick={() => setSubMenuOn(false)}>{isEn ? "Overview" : "회사개요"}</NavLink>
                            <NavLink to="/about?column=history" onClick={() => setSubMenuOn(false)}>{isEn ? "History" : "연혁"}</NavLink>
                            <NavLink to="/about?column=partners" onClick={() => setSubMenuOn(false)}>{isEn ? "Partners" : "파트너사"}</NavLink>
                        </div>
                        <div style={{ display: "none" }} className={isEn ? style.esubMenuItem : style.subMenuItem}>
                            <NavLink to="/show?column=concert" onClick={() => setSubMenuOn(false)}>{isEn ? "Concert" : "콘서트"}</NavLink>
                            <NavLink to="/show?column=musical" onClick={() => setSubMenuOn(false)}>{isEn ? "Musical" : "뮤지컬"}</NavLink>
                            <NavLink to="/show?column=festival" onClick={() => setSubMenuOn(false)}>{isEn ? "Festival" : "페스티벌"}</NavLink>
                        </div>
                        <div className={isEn ? style.esubMenuItem : style.subMenuItem}>
                            {/* <NavLink to="/video?column=overview" onClick={() => setSubMenuOn(false)}>{isEn ? "Studio the black" : "Studio the black"}</NavLink> */}
                            <NavLink to="/video?column=producer" onClick={() => setSubMenuOn(false)}>{isEn ? "Producer" : "프로듀서"}</NavLink>
                            <NavLink to="/video?column=work" onClick={() => setSubMenuOn(false)}>{isEn ? "Work" : "작품"}</NavLink>
                        </div>
                        <div className={isEn ? style.esubMenuItem : style.subMenuItem}>
                            <NavLink to="/food?column=kkotmareum" onClick={() => setSubMenuOn(false)}>{isEn ? "Kkotmareum" : "꽃마름"}</NavLink>
                        </div>
                        <div className={isEn ? style.esubMenuItem : style.subMenuItem}>
                            <NavLink to="/enter?column=enter" onClick={() => setSubMenuOn(false)}>{isEn ? "Entertainment" : "엔터사업부"}</NavLink>
                        </div>
                        <div className={isEn ? style.esubMenuItem : style.subMenuItem}>
                            <NavLink to="/sfsteel?column=eoutline" onClick={() => setSubMenuOn(false)}>{isEn ? "Overview" : "회사개요"}</NavLink>
                            <NavLink to="/sfsteel?column=scrab" onClick={() => setSubMenuOn(false)}>{isEn ? "About Scrap" : "스크랩 사업소개"}</NavLink>
                            <NavLink to="/sfsteel?column=sales" onClick={() => setSubMenuOn(false)}>{isEn ? "Partners" : "주요매출처"}</NavLink>
                            <NavLink to="/sfsteel?column=organization" onClick={() => setSubMenuOn(false)}>{isEn ? "Organization" : "조직도"}</NavLink>
                        </div>
                        <div className={isEn ? style.esubMenuItem : style.subMenuItem}>
                            <NavLink to="/pr?column=notice" onClick={() => setSubMenuOn(false)}>{isEn ? "Notice" : "공지사항"}</NavLink>
                            <NavLink to="/pr?column=advertisement" onClick={() => setSubMenuOn(false)}>{isEn ? "Advertisement" : "언론 및 광고"}</NavLink>
                            <NavLink to="/pr?column=press" onClick={() => setSubMenuOn(false)}>{isEn ? "Press" : "보도자료"}</NavLink>
                            <NavLink to="/pr?column=disclosure" onClick={() => setSubMenuOn(false)}>{isEn ? "Disclosure" : "공시자료"}</NavLink>
                            <NavLink to="/pr?column=policy" onClick={() => setSubMenuOn(false)}>{isEn ? "Policy" : "회사규정"}</NavLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className={style.wrapMO}>
                <div className={style.wrapContainer}>
                    <Link to="/">
                        <img src={`${isEn ? logoE : logo}`} alt="header-logo" id={style.headerLogo}></img>
                    </Link>
                    <img src={menu} alt="header-menu" id={style.headerMenu} onClick={() => showMenu("100vh")}></img>
                </div>
                <div ref={menuRef} className={style.mobileContainer}>
                    <div className={style.innerWrapContainer}>
                        <Link to="/">
                            <img src={logo} alt="header-logo" onClick={() => showMenu(0)} id={style.headerLogo}></img>
                        </Link>
                        <div className={style.innerRight}>
                            <button id={style.langBtn} onClick={() => setIsEn(!isEn)}>
                                {isEn ? "KO" : "EN"}
                                <img src={lang} alt="lang-icon" id={style.langIcon}></img>
                            </button>
                            <img src={menu} alt="header-menu" id={style.headerMenu} onClick={() => showMenu(0)}></img>
                        </div>
                    </div>
                    <div className={style.menuContainer}>
                        <div className={style.menuItem}>
                            <div className={style.itemMain}>
                                {isEn ? "About" : "회사소개"}
                            </div>
                            <div className={style.itemSub}>
                                <NavLink to="/about?column=outline" onClick={() => showMenu(0)}>{isEn ? "Overview" : "회사개요"}</NavLink>
                                <NavLink to="/about?column=history" onClick={() => showMenu(0)}>{isEn ? "History" : "연혁"}</NavLink>
                                <NavLink to="/about?column=partners" onClick={() => showMenu(0)}>{isEn ? "Partners" : "파트너사"}</NavLink>
                            </div>
                        </div>
                        <div className={style.menuItem} style={{ display: "none" }}>
                            <div className={style.itemMain}>
                                {isEn ? "Performance Dept." : "공연사업부"}
                            </div>
                            <div className={style.itemSub}>
                                <NavLink to="/show?column=concert" onClick={() => showMenu(0)}>{isEn ? "Concert" : "콘서트"}</NavLink>
                                <NavLink to="/show?column=musical" onClick={() => showMenu(0)}>{isEn ? "Musical" : "뮤지컬"}</NavLink>
                                <NavLink to="/show?column=festival" onClick={() => showMenu(0)}>{isEn ? "Festival" : "페스티벌"}</NavLink>
                            </div>
                        </div>
                        <div className={style.menuItem}>
                            <div className={style.itemMain}>
                                {isEn ? "Media Business Dept." : "미디어사업부"}
                            </div>
                            <div className={style.itemSub}>
                                {/* <NavLink to="/video?column=overview" onClick={() => showMenu(0)}>{isEn ? "Studio the black" : "Studio the black"}</NavLink> */}
                                <NavLink to="/video?column=producer" onClick={() => showMenu(0)}>{isEn ? "Producer" : "프로듀서"}</NavLink>
                                <NavLink to="/video?column=work" onClick={() => showMenu(0)}>{isEn ? "Work" : "작품"}</NavLink>
                            </div>
                        </div>
                        <div className={style.menuItem}>
                            <div className={style.itemMain}>
                                {isEn ? "Food Service Dept." : "외식사업부"}
                            </div>
                            <div className={style.itemSub}>
                                <NavLink to="/food?column=kkotmareum" onClick={() => showMenu(0)}>{isEn ? "Kkotmareum" : "꽃마름"}</NavLink>
                            </div>
                        </div>
                        <div className={style.menuItem}>
                            <div className={style.itemMain}>
                                {isEn ? "Entertainment Dept." : "엔터사업부"}
                            </div>
                            <div className={style.itemSub}>
                                <NavLink to="/enter?column=enter" onClick={() => showMenu(0)}>{isEn ? "Entertainment" : "엔터사업부"}</NavLink>
                            </div>
                        </div>
                        <div className={style.menuItem}>
                            <div className={style.itemMain}>
                                {isEn ? "SF Steel" : "SF Steel"}
                            </div>
                            <div className={style.itemSub}>
                                <NavLink to="/sfsteel?column=eoutline" onClick={() => showMenu(0)}>{isEn ? "Overview" : "회사개요"}</NavLink>
                                <NavLink to="/sfsteel?column=scrab" onClick={() => showMenu(0)}>{isEn ? "About Scrap" : "스크랩 사업소개"}</NavLink>
                                <NavLink to="/sfsteel?column=sales" onClick={() => showMenu(0)}>{isEn ? "Partners" : "주요매출처"}</NavLink>
                                <NavLink to="/sfsteel?column=organization" onClick={() => showMenu(0)}>{isEn ? "Organization" : "조직도"}</NavLink>
                            </div>
                        </div>
                        <div className={style.menuItem}>
                            <div className={style.itemMain}>
                                {isEn ? "PR" : "PR"}
                            </div>
                            <div className={style.itemSub}>
                                <NavLink to="/pr?column=notice" onClick={() => showMenu(0)}>{isEn ? "Notice" : "공지사항"}</NavLink>
                                <NavLink to="/pr?column=advertisement" onClick={() => showMenu(0)}>{isEn ? "Advertisement" : "언론 및 광고"}</NavLink>
                                <NavLink to="/pr?column=press" onClick={() => showMenu(0)}>{isEn ? "Press" : "보도자료"}</NavLink>
                                <NavLink to="/pr?column=disclosure" onClick={() => showMenu(0)}>{isEn ? "Disclosure" : "공시자료"}</NavLink>
                                <NavLink to="/pr?column=policy" onClick={() => showMenu(0)}>{isEn ? "Policy" : "회사규정"}</NavLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}