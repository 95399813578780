import style from "../css/Admin.module.css";
import exit from "../image/admin/admin_exit.svg";
import axios from "axios";
import { useSearchParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react";

export default function AdminShow({setShowAdmin, setRender}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const columnk = {concert: "콘서트", musical: "뮤지컬", festival: "페스티벌"}
    const [getData, setData] = useState();
    const [selectItem, setSelectItem] = useState({id: null, img: null, theme: null, e_theme: null, artist: null, e_artist: null, year: null});
    const itemRef = useRef([]);
    const imgRef = useRef();
    const fileRef = useRef();
    const [uploadFile, setUploadFile] = useState(null);

    function closeAdmin() {
        setShowAdmin(false);
    }

    function addNewItem() {
        if(selectItem.id === 0) {
            alert('현재 등록중인 데이터가 있습니다.')
            return
        }
        const emptyData = {
            id: 0,
            column: column,
            year: null,
            theme: '',
            e_theme: '',
            artist: '',
            e_artist: '',
            img: ''
        }
        setUploadFile(null);
        setData([emptyData, ...getData])
        setSelectItem([emptyData, ...getData][0])
    }

    function deleteItem(e, id) {
        e.stopPropagation();
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                setData(getData.filter(data => data.id !== 0))
                setSelectItem(getData.filter(data => data.id !== 0)[0])
            }
            return
        }
        if(window.confirm("정말 삭제하시겠습니까?")) {
            apiCallDelete(id)
        }
    }

    function changeTarget(idx, id) {
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                setData(getData.filter(data => data.id !== 0))
            } else {
                return
            }
        } else {
            if(
                Number(itemRef.current[0].value) !== selectItem.year ||
                itemRef.current[3].value !== selectItem.theme ||
                itemRef.current[4].value !== selectItem.e_theme ||
                imgRef.current.src !== selectItem.img
            ) {
                if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                    const newData = JSON.stringify({
                        field: 'show',
                        column: column,
                        id: selectItem.id,
                        year: itemRef.current[0].value,
                        theme: itemRef.current[3].value.replaceAll('\'', '\\\''),
                        e_theme: itemRef.current[4].value.replaceAll('\'', '\\\''),
                        artist: itemRef.current[1].value,
                        e_artist: itemRef.current[2].value,
                        origin_img: selectItem.img,
                        uploading: uploadFile !== null ? "y" : "n"
                    });
                    const formData = new FormData();
                    formData.set('imgFile', uploadFile)
                    formData.set('data', newData)
                    apiCallPut(formData);
                } else {
                    setUploadFile(null);
                }
            }
        }
        setSelectItem(getData[idx])
    }

    function saveData() {
        if(uploadFile === null) {
            alert('이미지를 등록해주세요.')
            return
        }
        if(selectItem.id === 0) {
            if(window.confirm('등록하시겠습니까?')) {
                const newData = JSON.stringify({
                    field: 'show',
                    column: column,
                    year: itemRef.current[0].value,
                    theme: itemRef.current[3].value.replaceAll('\'', '\\\''),
                    e_theme: itemRef.current[4].value.replaceAll('\'', '\\\''),
                    artist: itemRef.current[1].value,
                    e_artist: itemRef.current[2].value
                });
                const formData = new FormData();
                formData.set('data', newData)
                formData.set('imgFile', uploadFile)
                apiCallPost(formData);
            }
        } else {
            if(
                Number(itemRef.current[0].value) !== selectItem.year ||
                itemRef.current[3].value !== selectItem.theme ||
                itemRef.current[4].value !== selectItem.e_theme ||
                imgRef.current.src !== selectItem.img
            ) {
                if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                    const newData = JSON.stringify({
                        field: 'show',
                        column: column,
                        id: selectItem.id,
                        year: itemRef.current[0].value,
                        theme: itemRef.current[3].value.replaceAll('\'', '\\\''),
                        e_theme: itemRef.current[4].value.replaceAll('\'', '\\\''),
                        artist: itemRef.current[1].value,
                        e_artist: itemRef.current[2].value,
                        origin_img: selectItem.img,
                        uploading: uploadFile !== null ? "y" : "n"
                    });
                    const formData = new FormData();
                    formData.set('imgFile', uploadFile)
                    formData.set('data', newData)
                    apiCallPut(formData);
                } else {
                    setUploadFile(null);
                }
            }
        }
    }

    function fileRefCall(e) {
        if (e.target.value.length === 0) {
            return;
        } else if(e.target.files[0].size > e.target.size) {
            alert("파일 용량이 10MB를 초과합니다.");
            return
        } else {
            let reader = new FileReader();

            if(e.target.files[0]) {
                reader.readAsDataURL(e.target.files[0]);
                setUploadFile(e.target.files[0])
            }

            reader.onloadend = function() {
                imgRef.current.src = reader.result;
            }
        }
    }

    function uploadImage() {
        if (!fileRef.current) {
            return;
        }
        fileRef.current.click();
    }

    async function apiCallGet() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/show');
            setData(response.data.data[column])
            if(response.data.data[column].length !== 0) {
                setSelectItem(response.data.data[column][0])
            } else {
                setData([{id: 0,column: column,year: null,theme: '',e_theme: '',artist: '',e_artist: '',img: ''}])
                setSelectItem({id: 0,column: column,year: null,theme: '',e_theme: '',artist: '',e_artist: '',img: ''})
            }

        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPost(data) {
        try {
            const response = await axios.post(process.env.REACT_APP_SERVER + "/api/admin/add/show",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response.data.state === "success") {
                alert('등록이 완료되었습니다.');
                apiCallGet();
                setUploadFile(null);
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('등록에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPut(data) {
        try {
            const response = await axios.put(process.env.REACT_APP_SERVER + "/api/admin/put/show",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response.data.state === "success") {
                alert('변경이 완료되었습니다.');
                apiCallGet();
                setUploadFile(null);
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('변경에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallDelete(id) {
        try {
            const response = await axios.delete(process.env.REACT_APP_SERVER + "/api/admin/delete/show", {
                data: {
                    id: id,
                    img: getData.filter(data => data.id === id)[0]['img']
                }
            });
            if(response.data.state === "success") {
                alert('삭제가 완료되었습니다.');
                apiCallGet();
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('삭제에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    useEffect(() => {
        setColumn(searchParams.get("column"));
    }, [searchParams])

    useEffect(() => {
        apiCallGet()
    }, [])

    useEffect(() => {
        if(selectItem !== 'undefined') {
            itemRef.current[0].value = selectItem.year;
            itemRef.current[1].value = selectItem.artist;
            itemRef.current[2].value = selectItem.e_artist;
            itemRef.current[3].value = selectItem.theme;
            itemRef.current[4].value = selectItem.e_theme;
            imgRef.current.src = selectItem.img;
            if(selectItem.img !== '' && selectItem.img !== null) {
                setUploadFile({name: selectItem.img.split('/show_' + column + '/')[1]})
                console.log(selectItem.img.split('/show_' + column + '/')[1])
            }
        }
    }, [selectItem])

    return(
        <div className={ `${style.container} ${style.show}` }>
            <div className={ style.topContainer }>
                <span>공연 관리</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ `${style.middleContainer} ${style.middleShow}`}>
                <div className={style.showList}>
                    <span className={style.contentTheme}>{columnk[column]} 목록</span>
                    <hr className={style.mainHr}></hr>
                    <div className={style.listContainer}>
                        {
                            getData !== undefined &&
                            getData.map((data, idx) => (
                                <div key={data.id} className={selectItem.id === data.id ? `${style.listItem} ${style.selected}` : style.listItem} onClick={selectItem.id !== data.id ? () => changeTarget(idx, data.id) : null}>
                                    <span>{idx+1}</span>
                                    <span>{data.theme}</span>
                                    <div className={ style.deleteItemBtn } onClick={ (e) => deleteItem(e, data.id) }>
                                        <div>
                                            <hr></hr>
                                            <hr></hr>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <button className={style.itemAddBtn} onClick={addNewItem}>{columnk[column]} 추가</button>
                </div>
                <div className={style.showContent}>
                    <span className={style.contentTheme}>{columnk[column]} 등록/수정</span>
                    <hr className={style.mainHr}></hr>
                    <div className={style.contentSeperate}>
                        <div className={style.contentLeft}>
                            <span className={style.infoTheme}>{columnk[column]} 정보</span>
                            <div className={style.contentContainer}>
                                <div className={`${style.inputItem} ${style.show400}`}>
                                    <span className={style.inputTheme}>공연년도</span>
                                    <span className={style.need}>*필수</span>
                                    <input type="text" ref={ el => (itemRef.current[0] = el) } style={{width: "289px"}}></input>
                                </div>
                                <div className={`${style.inputItem} ${style.show400}`}>
                                    <span className={style.inputTheme}>한글대표아티스트</span>
                                    <input type="text" ref={ el => (itemRef.current[1] = el) } style={{width: "240px"}}></input>
                                </div>
                                <div className={`${style.inputItem} ${style.show400}`}>
                                    <span className={style.inputTheme}>영문대표아티스트</span>
                                    <input type="text" ref={ el => (itemRef.current[2] = el) } style={{width: "240px"}}></input>
                                </div>
                                <hr></hr>
                                <div className={`${style.inputItem} ${style.show400}`}>
                                    <span className={style.inputTheme}>한글제목</span>
                                    <span className={style.need}>*필수</span>
                                    <input type="text" ref={ el => (itemRef.current[3] = el) } style={{width: "289px"}}></input>
                                </div>
                                <div className={`${style.inputItem} ${style.show400}`}>
                                    <span className={style.inputTheme}>영문제목</span>
                                    <span className={style.need}>*필수</span>
                                    <input type="text" ref={ el => (itemRef.current[4] = el) } style={{width: "289px"}}></input>
                                </div>
                            </div>
                        </div>
                        <div className={ column === "festival" ? `${style.contentRight} ${style.wide}` : style.contentRight}>
                            <span className={style.infoTheme}>{columnk[column]} 이미지</span>
                            <span className={style.need}>*필수</span>
                            <span className={style.uploadSpan}>- 10mb 미만의 이미지만 업로드할 수 있습니다.</span>
                            <div className={style.imgContainer} onClick={(e) => uploadImage(e)}>
                                <div className={ style.imgIntroContainer }>
                                    <div className={style.plusShape2}>
                                        <hr></hr>
                                        <hr></hr>
                                    </div>
                                    <span className={style.introMent2}>{column === "festival" ? "640*426" : "465*310"}px 이상의 이미지 업로드를 권장합니다. (다른 비율로 업로드 시 이미지 비율 훼손)</span>
                                </div>
                                <img ref={imgRef} src="" alt="" onError={(e) => e.target.style = style.none}></img>
                                <input type="file" accept="image/*" ref={ fileRef } onChange={ (e) => fileRefCall(e) } size="10485760"></input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={saveData}>
                    <span>저장하기</span>
                </div>
            </div>
        </div>
    )
}