import { useSearchParams } from "react-router-dom"
import style from "../css/Show.module.css"
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import SEOMetaTag from "../Components/SEOMetaTag";
import Portal from '../Components/Portal';
import AdminModal from '../AdminComponents/AdminModal';

export default function Show({isEn, admin, setAdmin, showAdmin, setShowAdmin, adminField, setAdminField, render, setRender}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const [padding, setPadding] = useState([20, 20, 20])
    const circleRef = useRef();
    const hrRef = useRef();
    const contentRef = useRef();
    const columnData = ["concert", "musical", "festival"];
    const columnKorData = ["콘서트", "뮤지컬", "페스티벌"];
    const columnEnData = ["Concert", "Musical", "Festival"];
    const [getData, setData] = useState();
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    const [autoHeight, setAutoHeight] = useState([0, 0, 0]);

    async function apiCall() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/show');
            setData(response.data.data)
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    function changeColumn(column) {
        searchParams.set("column", column)
        setSearchParams(searchParams)
    }

    function getPadding(dataCount, size) {
        var _padding = 20;
        var itemWidth = 0;

        if(document.body.clientWidth > 1317) {
            return 0;
        }
        if(size === "s") {
            itemWidth = 152;
        } else if(size === "b") {
            itemWidth = 320;
        }

        if(size === "b" && dataCount <= 1 && document.body.clientWidth < 676) {
            _padding = (document.body.clientWidth - itemWidth) / 2;
        } else {
            for(var i=1; i<=dataCount; i++) {
                if((itemWidth * i + 16 * (i - 1)) > document.body.clientWidth) {
                    _padding = (document.body.clientWidth - (itemWidth * (i - 1) + 16 * (i - 2))) / 2;
                    break
                }
            }
        }
        return _padding;
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(document.body.clientWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(document.body.clientWidth);
            });
        }
    }, [])

    useEffect(() => {
        setColumn(searchParams.get("column"));
        t()
        switch (columnData.indexOf(searchParams.get("column"))) {
            case 0:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
            case 1:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-100%)";
                contentRef.current.parentNode.style.height = autoHeight[1] + "px";
                return
            case 2:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.clientWidth - 16 + "px";
                } else {
                    circleRef.current.style.left = hrRef.current.clientWidth + 12 + "px";
                }
                contentRef.current.style.transform = "translateX(-200%)";
                contentRef.current.parentNode.style.height = autoHeight[2] + "px";
                return
            default:
                setColumn("concert");
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
        }
    }, [searchParams, screenWidth, autoHeight])

    useEffect(() => {
        if(getData === undefined) {
            setPadding([0, 0, 0]);
        } else {
            padding[0] = getPadding(getData.concert.length, 's')
            padding[1] = getPadding(getData.musical.length, 's')
            padding[2] = getPadding(getData.festival.length, 'b')
            setPadding([...padding])
            window.addEventListener('resize', () => {
                if(document.body.clientWidth > 1317) {
                    setPadding([0, 0, 0]);
                } else {
                    padding[0] = getPadding(getData.concert.length, 's')
                    padding[1] = getPadding(getData.musical.length, 's')
                    padding[2] = getPadding(getData.festival.length, 'b')
                    setPadding([...padding])
                }
            });
            return () => {
                window.removeEventListener('resize', () => {
                    if(document.body.clientWidth > 1317) {
                        setPadding([0, 0, 0]);
                    } else {
                        padding[0] = getPadding(getData.concert.length, 's')
                        padding[1] = getPadding(getData.musical.length, 's')
                        padding[2] = getPadding(getData.festival.length, 'b')
                        setPadding([...padding])
                    }
                });
            }
        }
    }, [getData]);

    useEffect(() => {
        apiCall();
    }, []);

    function t(e) {
        if(JSON.stringify(autoHeight) !== JSON.stringify([
            contentRef.current.childNodes[0].clientHeight,
            contentRef.current.childNodes[1].clientHeight,
            contentRef.current.childNodes[2].clientHeight
        ])) {
            setAutoHeight([
                contentRef.current.childNodes[0].clientHeight,
                contentRef.current.childNodes[1].clientHeight,
                contentRef.current.childNodes[2].clientHeight
            ]);
        }
    }

    return(
        <div className={style.wrap} onLoad={t}>
            <SEOMetaTag title="공연사업부" description="한주에이알티 주식회사 공연사업부 페이지" url="https://hanjooart.com/show"></SEOMetaTag>
            <div className={style.topContainer}>
                <span>{isEn ? "Performance Dept." : "공연사업부"}</span>
                <div ref={hrRef} className={style.columnContainer}>
                    {
                        columnData.map((data, idx) => (
                            <span key={idx} onClick={() => changeColumn(data)} className={data === column ? `${style.columnContainerItem} ${style.selected}` : style.columnContainerItem}>{isEn ? columnEnData[idx] : columnKorData[idx]}</span>
                        ))
                    }
                </div>
                <div ref={circleRef} className={style.hrCircle}></div>
            </div>
            <div className={style.bottomContainer}>
                <div ref={contentRef} className={style.bottomContainerWrap}>
                    <div key={Math.random()} className={style.contentContainer} style={{padding: "0 " + padding[0] + "px"}}>
                        {
                            getData !== undefined ?
                                getData.concert.length === 0 ?
                                    <div className={style.concertItem}>
                                        <img src={document.body.clientWidth > 1317 ? "https://enterpartners-bucket.s3.ap-northeast-2.amazonaws.com/src/nodata_s_p.svg" : "https://enterpartners-bucket.s3.ap-northeast-2.amazonaws.com/src/nodata_s_m.svg"} alt="nodata-img"></img>
                                        <span> </span>
                                    </div>
                                :
                                getData.concert.map((data) => (
                                    <div key={data.id} className={style.concertItem}>
                                        <img src={data.img} alt="concert-img"></img>
                                        <span>{data.year} {isEn ? data.e_artist : data.artist}<br />{isEn ? data.e_theme : data.theme}</span>
                                    </div>
                                ))
                            :
                                null
                        }
                    </div>
                    <div className={style.contentContainer} style={{padding: "0 " + padding[1] + "px"}}>
                        {
                            getData !== undefined ?
                                getData.musical.length === 0 ?
                                    <div className={style.musicalItem}>
                                        <img src={document.body.clientWidth > 1317 ? "https://enterpartners-bucket.s3.ap-northeast-2.amazonaws.com/src/nodata_s_p.svg" : "https://enterpartners-bucket.s3.ap-northeast-2.amazonaws.com/src/nodata_s_m.svg"} alt="nodata-img"></img>
                                        <span> </span>
                                    </div>
                                :
                                getData.musical.map((data) => (
                                    <div key={data.id} className={style.musicalItem}>
                                        <img src={data.img} alt="musical-img"></img>
                                        <span>{data.year} {isEn ? data.e_artist : data.artist}<br />{isEn ? data.e_theme : data.theme}</span>
                                    </div>
                                ))
                            :
                                null
                        }
                    </div>
                    <div className={style.contentContainer} style={{padding: "0 " + padding[2] + "px"}}>
                        {
                            getData !== undefined ?
                            getData.festival.length === 0 ?
                                    <div className={style.festivalItem}>
                                        <img src={document.body.clientWidth > 1317 ? "https://enterpartners-bucket.s3.ap-northeast-2.amazonaws.com/src/nodata_b_p.svg" : "https://enterpartners-bucket.s3.ap-northeast-2.amazonaws.com/src/nodata_b_m.svg"} alt="nodata-img"></img>
                                        <span> </span>
                                    </div>
                                :
                                getData.festival.map((data) => (
                                    <div key={data.id} className={style.festivalItem}>
                                        <img src={data.img} alt="festival-img"></img>
                                        <span>{data.year} {isEn ? data.e_artist : data.artist}<br />{isEn ? data.e_theme : data.theme}</span>
                                    </div>
                                ))
                            :
                                null
                        }
                    </div>                    
                </div>
            </div>
            {
                admin &&
                <div className={style.adminContainer}>
                    <button className={style.adminBtn} onClick={() => {setShowAdmin(true); setAdminField('show')}}>{columnKorData[columnData.indexOf(column)]} 관리</button>
                </div>
            }
            <Portal>
                {
                    showAdmin &&
                    <AdminModal setRender={ setRender } admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField}></AdminModal>
                }
            </Portal>
        </div>
    )
}