import style from "../css/Admin.module.css";
import exit from "../image/admin/admin_exit.svg";
import axios from "axios";
import { useSearchParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react";

export default function AdminDetail({setShowAdmin, setRender}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const columnk = {notice: "공지사항", advertisement: "언론 및 광고", press: "보도자료", disclosure: "공시자료"}
    const [getData, setData] = useState();
    const itemRef = useRef([]);
    const fileRef = useRef();
    const fileRefMo = useRef();
    const fRef = useRef();
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadFileMo, setUploadFileMo] = useState(null);
    const [uploadF, setUploadF] = useState([]);
    const [imageCopy, setImageCopy] = useState(false);

    function closeAdmin() {
        setShowAdmin(false);
    }

    function saveData() {
        if(column === "notice") {
            if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                const newData = JSON.stringify({
                    field: 'pr',
                    column: column,
                    id: getData[0].id,
                    theme: itemRef.current[0].value.replaceAll('\'', '\\\''),
                    e_theme: itemRef.current[1].value.replaceAll('\'', '\\\''),
                    date: itemRef.current[2].value,
                    e_date: itemRef.current[3].value,
                    content: itemRef.current[4].value.replaceAll('\'', '\\\''),
                    e_content: itemRef.current[5].value.replaceAll('\'', '\\\''),
                    origin_img_p: getData[0].imgurl,
                    origin_img_m: getData[0].m_imgurl,
                    origin_files: getData[0].file,
                });
                const formData = new FormData();
                formData.set('imgFile', uploadFile)
                formData.set('data', newData)
                uploadF.map((file) => (
                    formData.append('file', file)
                ))
                if(imageCopy) {
                    formData.set('imgFileMo', uploadFile)
                } else {
                    formData.set('imgFileMo', uploadFileMo)
                }
                apiCallPut(formData);
            }
        } else {
            if(
                itemRef.current[0].value !== getData[0].theme ||
                itemRef.current[1].value !== getData[0].e_theme ||
                itemRef.current[2].value !== getData[0].date ||
                itemRef.current[3].value !== getData[0].e_date ||
                itemRef.current[4].value !== getData[0].content ||
                itemRef.current[5].value !== getData[0].e_content ||
                (uploadFile === null ? (getData[0].img === undefined ? '' : getData[0].img) !== '' : uploadFile.name !== getData[0].img.split('/pr_' + column + '/')[1])
            ) {
                if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                    const newData = JSON.stringify({
                        field: 'pr',
                        column: column,
                        id: getData[0].id,
                        theme: itemRef.current[0].value.replaceAll('\'', '\\\''),
                        e_theme: itemRef.current[1].value.replaceAll('\'', '\\\''),
                        date: itemRef.current[2].value,
                        e_date: itemRef.current[3].value,
                        content: itemRef.current[4].value.replaceAll('\'', '\\\''),
                        e_content: itemRef.current[5].value.replaceAll('\'', '\\\''),
                        origin_img: getData[0].img === undefined ? '' : getData[0].img,
                        uploading: uploadFile !== null ? "y" : "n"
                    });
                    const formData = new FormData();
                    formData.set('imgFile', uploadFile)
                    formData.set('data', newData)
                    apiCallPut(formData);
                }
            }
        }
    }

    function fileRefCall(e, id) {
        if (e.target.value.length === 0) {
            return;
        }
        if(id === "f") {
            for(var i=0;i<e.target.files.length;i++) {
                if(e.target.files[0].length > e.target.size) {
                    alert("파일 용량이 20MB를 초과합니다.");
                    return
                }
            }
        } else {
            if(e.target.files[0].size > e.target.size) {
                alert("파일 용량이 10MB를 초과합니다.");
                return
            }
        }
        let reader = new FileReader();

        if(e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
            if(id === "web") {
                setUploadFile(e.target.files[0])
                if(imageCopy) {
                    setUploadFileMo(e.target.files[0])
                }
            } else if(id === "mobile") {
                setUploadFileMo(e.target.files[0])
            } else if(id === "f") {
                if((e.target.files.length + uploadF.length) > 3) {
                    alert('파일 첨부는 최대 3개까지 가능합니다.');
                    return
                } else if(uploadF.length !== 0) {
                    for(var i=0;i<uploadF.length;i++) {
                        for(var j=0;j<Array.from(e.target.files).length;j++) {
                            if(uploadF[i].name === e.target.files[j].name) {
                                alert('중복된 파일이 존재합니다.')
                                return
                            }
                        }
                    }
                }
                setUploadF([...uploadF, ...Array.from(e.target.files)]);
            }
        }

        reader.onloadend = function() {
        }
    }

    function uploadImage(e) {
        var ref = null;
        if(e.currentTarget.id === "web") {
            ref = fileRef.current;
        } else if(e.currentTarget.id === "mobile") {
            if(imageCopy) {
                return
            } else {
                ref = fileRefMo.current;
            }
        } else {
            ref = fRef.current;
        }
        if (!ref) {
            return;
        }
        ref.click();
    }

    async function apiCallGet() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER + '/api/data/detail/' + column + '/' + searchParams.get("postId"));
            setData(response.data.data)
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPut(data) {
        try {
            const response = await axios.put(process.env.REACT_APP_SERVER + "/api/admin/put/pr",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response.data.state === "success") {
                alert('변경이 완료되었습니다.');
                apiCallGet();
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('변경에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    useEffect(() => {
        setColumn(searchParams.get("column"));
    }, [searchParams])

    useEffect(() => {
        if(column === "notice") {
            if(imageCopy) {
                if(fileRef !== null && uploadFile !== null) {
                    setUploadFileMo(uploadFile)
                }
            } else {
                setUploadFileMo(null);
                fileRefMo.current.value = "";
            }
        }
    }, [imageCopy])

    function fakeFile(url) {
        var binaryVal;
        var inputMIME = url.split(',')[0].split(':')[1].split(';')[0];

        if (url.split(',')[0].indexOf('base64') >= 0){
            binaryVal = atob(url.split(',')[1]);
        } else {
            binaryVal = unescape(url.split(',')[1]);
        }
        var blobArray = [];
        for (var index = 0; index < binaryVal.length; index++) {
            blobArray.push(binaryVal.charCodeAt(index));
        }

        const file = new File([new Blob([blobArray], {
            type: inputMIME
        })], url.split('/')[url.split('/').length - 1])
        return file
    }

    useEffect(() => {
        apiCallGet()
    }, [])

    useEffect(() => {
        if(getData !== undefined) {
            itemRef.current[0].value = getData[0].theme;
            itemRef.current[1].value = getData[0].e_theme;
            itemRef.current[2].value = getData[0].date;
            itemRef.current[3].value = getData[0].e_date;
            itemRef.current[4].value = getData[0].content;
            itemRef.current[5].value = getData[0].e_content;
            if(column !== "disclosure" && column !== "notice" && getData[0].img !== '') {
                setUploadFile(fakeFile(getData[0].img))
            }
            if(column === "notice") {
                if(getData[0].imgurl !== '') {
                    setUploadFile(fakeFile(getData[0].imgurl))
                }
                if(getData[0].m_imgurl !== '') {
                    setUploadFileMo(fakeFile(getData[0].m_imgurl))
                }
                if(getData[0].file.length !== 0) {
                    let cp = [];
                    getData[0].file.map((f) => (
                        cp.push(fakeFile(f))
                    ))
                    setUploadF(cp);
                }
            }
        }
    }, [getData])

    return(
        <div className={ `${style.container} ${style.pr}` }>
            <div className={ style.topContainer }>
                <span>{columnk[column]} 게시물 관리</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ `${style.middleContainer} ${style.middlePr}`}>
                <div className={style.prContent}>
                    <span className={style.contentTheme}>{columnk[column]} 등록/수정</span>
                    <hr className={style.mainHr}></hr>
                    <div>
                        <div className={style.prContentContainer}>
                            <div className={style.prInputItem}>
                                <span className={style.infoTheme}>제목</span>
                                <span className={style.need}>*필수</span>
                                <span>- 국문 버전에 보여집니다. 공백 포함 50자 내</span>
                                <div className={`${style.inputItem} ${style.pr600}`}>
                                    <input type="text" ref={ el => (itemRef.current[0] = el) } style={{width: "568px"}} maxLength="50"></input>
                                </div>
                            </div>
                            <div className={style.prInputItem}>
                                <span className={style.infoTheme}>Title</span>
                                <span className={style.need}>*필수</span>
                                <span>- 영문 버전에 보여집니다. 공백 포함 50자 내</span>
                                <div className={`${style.inputItem} ${style.pr600}`}>
                                    <input type="text" ref={ el => (itemRef.current[1] = el) } style={{width: "568px"}} maxLength="50"></input>
                                </div>
                            </div>
                            <div className={style.prInputItem}>
                                <span className={style.infoTheme}>날짜</span>
                                <span className={style.need}>*필수</span>
                                <span>- 국문 버전에 보여집니다.</span>
                                <div className={`${style.inputItem} ${style.pr600}`}>
                                    <input type="text" ref={ el => (itemRef.current[2] = el) } style={{width: "568px"}}></input>
                                </div>
                            </div>
                            <div className={style.prInputItem}>
                                <span className={style.infoTheme}>Date</span>
                                <span className={style.need}>*필수</span>
                                <span>- 영문 버전에 보여집니다.</span>
                                <div className={`${style.inputItem} ${style.pr600}`}>
                                    <input type="text" ref={ el => (itemRef.current[3] = el) } style={{width: "568px"}}></input>
                                </div>
                            </div>
                            {
                                column !== "disclosure" ?
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>내용</span>
                                    <span className={style.need}>*필수</span>
                                    <span>- 국문 버전에 보여집니다. 공백 포함 5,000자 내</span>
                                    <div className={`${style.inputItem} ${style.pr600160}`}>
                                        <textarea type="text" ref={ el => (itemRef.current[4] = el) } style={{width: "568px"}} maxLength="5000"></textarea>
                                    </div>
                                </div>
                                :
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>출처</span>
                                    <span className={style.need}>*필수</span>
                                    <div className={`${style.inputItem} ${style.pr600}`}>
                                        <input type="text" ref={ el => (itemRef.current[4] = el) } style={{width: "568px"}}></input>
                                    </div>
                                </div>
                            }
                            {
                                column !== "disclosure" ?
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>Content</span>
                                    <span className={style.need}>*필수</span>
                                    <span>- 영문 버전에 보여집니다. 공백 포함 5,000자 내</span>
                                    <div className={`${style.inputItem} ${style.pr600160}`}>
                                        <textarea type="text" ref={ el => (itemRef.current[5] = el) } style={{width: "568px"}} maxLength="5000"></textarea>
                                    </div>
                                </div>
                                :
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>Source</span>
                                    <span className={style.need}>*필수</span>
                                    <div className={`${style.inputItem} ${style.pr600}`}>
                                        <input type="text" ref={ el => (itemRef.current[5] = el) } style={{width: "568px"}}></input>
                                    </div>
                                </div>
                            }
                            {
                                column === "disclosure" &&
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>링크</span>
                                    <span className={style.need}>*필수</span>
                                    <div className={`${style.inputItem} ${style.pr600}`}>
                                        <input type="text" ref={ el => (itemRef.current[6] = el) } style={{width: "568px"}}></input>
                                    </div>
                                </div>
                            }
                        </div>
                        <hr></hr>
                        {
                            column !== "disclosure" &&
                            <div className={style.prFileContainer}>
                                <div className={style.fileSpan}>
                                    <span className={style.infoTheme}>이미지 첨부{column === "notice" ? "(Web)" : null}</span>
                                    <span>- 최대 1장(jpg/png), 가로사이즈 최대 1260px 권장</span>
                                </div>
                                <button id="web" className={style.fileAddBtn} onClick={(e) => uploadImage(e)}>첨부하기</button>
                                <input type="file" id="web" accept="image/*" ref={ fileRef } onChange={ (e) => fileRefCall(e, "web") } size="10485760"></input>
                                {
                                    uploadFile &&
                                    <div className={column === "notice" ? `${style.prUploadFile} ${style.limitLength}` : style.prUploadFile}>
                                        <span>{uploadFile.name}</span>
                                        <div className={style.fileDelBtn} onClick={() => {
                                                setUploadFile(null);
                                                fileRef.current.value = ""
                                                if(imageCopy) {
                                                    setUploadFileMo(null);
                                                    fileRefMo.current.value = ""
                                                }
                                            }}>
                                            <div>
                                                <hr></hr>
                                                <hr></hr>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    column === "notice" &&
                                    <>
                                        <div className={style.fileSpan}>
                                            <span className={style.infoTheme}>이미지 첨부(Mobile)</span>
                                            <span>웹과 동일하게
                                                <div className={style.checkBoxContainer} onClick={() => setImageCopy(!imageCopy)}>
                                                    <div className={imageCopy ? style.checkBoxInner : null}></div>
                                                </div>
                                            </span>
                                        </div>
                                        <button id="mobile" className={style.fileAddBtn} onClick={(e) => uploadImage(e)}>첨부하기</button>
                                        <input type="file" id="mobile" accept="image/*" ref={ fileRefMo } onChange={ (e) => fileRefCall(e, "mobile") } size="10485760"></input>
                                        {
                                            uploadFileMo &&
                                            <div className={`${style.prUploadFile} ${style.limitLength}`}>
                                                <span>{uploadFileMo.name}</span>
                                                <div className={style.fileDelBtn} onClick={() => {
                                                        setUploadFileMo(null);
                                                        fileRefMo.current.value = ""
                                                        if(imageCopy) {
                                                            setUploadFile(null);
                                                            fileRef.current.value = ""
                                                        }
                                                    }}>
                                                    <div>
                                                        <hr></hr>
                                                        <hr></hr>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                        }
                        {
                            column === "notice" &&
                            <div className={style.prFileContainer}>
                                <div className={style.fileSpan}>
                                    <span className={style.infoTheme}>파일 첨부</span>
                                    <span>- 최대 3개, 1개당 최대 20MB</span>
                                </div>
                                <button className={style.fileAddBtn} onClick={(e) => uploadImage(e)}>첨부하기</button>
                                <input type="file" accept="*/*" ref={fRef} onChange={ (e) => fileRefCall(e, "f") } size="20971520" multiple={true}></input>
                                {
                                    uploadF.map((item, idx) => (
                                        <div key={idx} className={column === "notice" ? `${style.prUploadFile} ${style.limitLength}` : style.prUploadFile}>
                                            <span>{
                                                item.name.indexOf('/file/') !== -1 ?
                                                item.name.split('/file/')[1]
                                                :
                                                item.name
                                            }</span>
                                            <div className={style.fileDelBtn} onClick={() => {
                                                    setUploadF([...uploadF.filter((initem) => initem.name !== item.name)]);
                                                    fRef.current.value = ""
                                                }}>
                                                <div>
                                                    <hr></hr>
                                                    <hr></hr>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        }
                    </div>
                </div>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={saveData}>
                    <span>저장하기</span>
                </div>
            </div>
        </div>
    )
}