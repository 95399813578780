import style from "../css/Admin.module.css";
import exit from "../image/admin/admin_exit.svg";
import axios from "axios";
import { useEffect, useRef, useState } from "react";

export default function AdminMain({setShowAdmin, setRender}) {
    const [getData, setData] = useState();
    const [selectItem, setSelectItem] = useState({id: null, img: null});
    const imgRef = useRef();
    const fileRef = useRef();
    const [uploadFile, setUploadFile] = useState(null);

    function closeAdmin() {
        setShowAdmin(false);
    }

    function addNewItem() {
        if(getData[0].id === 0) {
            alert('현재 등록중인 데이터가 있습니다.')
            return
        }
        const emptyData = {id: 0, img: ''}
        fileRef.current.value = null;
        imgRef.current.src = null;
        setData([emptyData, ...getData])
        setSelectItem([emptyData, ...getData][0])
    }

    function deleteItem(e, id) {
        e.stopPropagation();
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                fileRef.current.value = null;
                setData(getData.filter(data => data.id !== 0))
                setSelectItem(getData.filter(data => data.id !== 0)[0])
            }
            return
        }
        if(window.confirm("정말 삭제하시겠습니까?")) {
            apiCallDelete(id);
        }
    }

    function changeTarget(idx, id) {
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                fileRef.current.value = null;
                setData(getData.filter(data => data.id !== 0))
            } else {
                return
            }
        }
        setSelectItem(getData[idx])
    }

    function saveData() {
        if(uploadFile === null) {
            alert('이미지를 등록해주세요.')
            return
        }
        if(selectItem.id === 0) {
            if(window.confirm('등록하시겠습니까?')) {
                const formData = new FormData();
                formData.set('imgFile', uploadFile)

                apiCallPost(formData);
            }
        }
    }

    function fileRefCall(e) {
        if (e.target.value.length === 0) {
            return;
        } else if(e.target.files[0].size > e.target.size) {
            alert("파일 용량이 10MB를 초과합니다.");
            return
        } else {
            let reader = new FileReader();

            if(e.target.files[0]) {
                reader.readAsDataURL(e.target.files[0]);
                setUploadFile(e.target.files[0])
            }

            reader.onloadend = function() {
                imgRef.current.src = reader.result;
            }
        }
    }

    function uploadImage() {
        if (!fileRef.current) {
            return;
        }
        fileRef.current.click();
    }

    async function apiCallGet() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/main');
            setData(response.data.data.main)
            if(response.data.data.main.length !== 0) {
                setSelectItem(response.data.data.main[0])
            } else {
                setData([{id: 0, img: ''}])
                setSelectItem({id: 0, img: ''})
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPost(data) {
        try {
            const response = await axios.post(process.env.REACT_APP_SERVER + "/api/admin/add/main",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response.data.state === "success") {
                alert('등록이 완료되었습니다.');
                apiCallGet();
                setUploadFile(null);
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('등록에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallDelete(id) {
        try {
            const response = await axios.delete(process.env.REACT_APP_SERVER + "/api/admin/delete/main", {
                data: {
                    id: id,
                    img: getData.filter(data => data.id === id)[0]['img']
                }
            });
            if(response.data.state === "success") {
                alert('삭제가 완료되었습니다.');
                apiCallGet();
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('삭제에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    useEffect(() => {
        apiCallGet()
    }, [])

    useEffect(() => {
        getData !== undefined &&
        (imgRef.current.src = selectItem.img);
    }, [selectItem])

    return(
        <div className={ `${style.container} ${style.main}` }>
            <div className={ style.topContainer }>
                <span>메인 이미지 관리</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ `${style.middleContainer} ${style.middleMain}`}>
                <div className={style.mainList}>
                    <span className={style.contentTheme}>목록</span>
                    <span className={style.notice}>(3장 이상 등록 권장)</span>
                    <hr className={style.mainHr}></hr>
                    <div className={style.listContainer}>
                        {
                            getData !== undefined &&
                            getData.map((data, idx) => (
                                <div key={data.id} className={selectItem.id === data.id ? `${style.listItem} ${style.selected}` : style.listItem} onClick={selectItem.id !== data.id ? () => changeTarget(idx, data.id) : null}>
                                    <span>{idx+1}</span>
                                    <span>{data.img.split('/main/')[1]}</span>
                                    <div className={ style.deleteItemBtn } onClick={ (e) => deleteItem(e, data.id) }>
                                        <div>
                                            <hr></hr>
                                            <hr></hr>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <button className={style.itemAddBtn} onClick={addNewItem}>이미지 추가</button>
                </div>
                <div className={style.mainImg}>
                    <span className={style.contentTheme}>이미지 등록/수정</span>
                    <hr className={style.mainHr}></hr>
                    <div className={style.imgContainer} onClick={(e) => uploadImage(e)}>
                        <div className={ style.imgIntroContainer }>
                            <div className={style.plusShape}>
                                <hr></hr>
                                <hr></hr>
                            </div>
                            <span className={style.introMent}>이미지 사이즈는 1920x1080px로 업로드 해주세요.</span>
                        </div>
                        <img ref={imgRef} src="" alt="" onError={(e) => e.target.style = style.none}></img>
                        <input type="file" accept="image/*" ref={ fileRef } onChange={ (e) => fileRefCall(e) } size="10485760"></input>
                    </div>
                </div>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={saveData}>
                    <span>등록하기</span>
                </div>
            </div>
        </div>
    )
}