import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import { useState } from 'react';
import Main from './Pages/Main';
import Footer from './Components/Footer';
import Show from './Pages/Show';
import ScrollToTop from './Components/ScrollToTop';
import About from './Pages/About';
import Video from './Pages/Video';
import Food from './Pages/Food';
import Enter from './Pages/Enter';
import Detail from './Components/Detail';
import Sfsteel from './Pages/Sfsteel';
import Pr from './Pages/Pr';
import Studio from './Pages/Studio';

function App() {
  const [isEn, setIsEn] = useState(false);
  const [currentPageNotice, setCurrentPageNotice] = useState(1);
  const [currentPageNews, setCurrentPageNews] = useState(1);
  const [currentPageMedia, setCurrentPageMedia] = useState(1);
  const [currentPageDisc, setCurrentPageDisc] = useState(1);
  const [admin, setAdmin] = useState(window.localStorage.getItem("login_access") === "true" ? true : false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [adminField, setAdminField] = useState('');
  const [render, setRender] = useState();
  return (
    <div className="wrapper">
      <BrowserRouter>
        <ScrollToTop></ScrollToTop>
        <Header isEn={isEn} setIsEn={setIsEn}></Header>
        <Routes>
          <Route key="about" path="/about" element={ <About isEn={isEn} admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField} render={render} setRender={setRender} />} />
          {/*<Route key="show" path="/show" element={ <Show isEn={isEn} admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField} render={render} setRender={setRender} />} />*/}
          <Route key="video" path="/video" element={ <Video isEn={isEn} admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField} render={render} setRender={setRender} />} />
          <Route key="food" path="/food" element={ <Food isEn={isEn} admin={admin} />} />
          <Route key="enter" path="/enter" element={ <Enter isEn={isEn} admin={admin} />} />
          <Route key="studio" path="/studio" element={ <Studio isEn={isEn} admin={admin} />} />
          <Route key="Sf" path="/sfsteel" element={ <Sfsteel isEn={isEn} admin={admin} />} />
          <Route key="pr" path="/pr" element={ <Pr isEn={isEn} currentPageNews={currentPageNews} setCurrentPageNews={setCurrentPageNews} currentPageNotice={currentPageNotice} setCurrentPageNotice={setCurrentPageNotice} currentPageMedia={currentPageMedia} setCurrentPageMedia={setCurrentPageMedia} currentPageDisc={currentPageDisc} setCurrentPageDisc={setCurrentPageDisc} admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField} render={render} setRender={setRender} />} />
          <Route key="detail" path="/pr/detail" element={ <Detail isEn={isEn} admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField} render={render} setRender={setRender} />} />
          <Route key="main" path="" element={ <Main isEn={isEn} admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField} render={render} setRender={setRender} /> } />
        </Routes>
        <Footer isEn={isEn} admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField}></Footer>
      </BrowserRouter>
    </div>
  );
}

export default App;
