import style from "../css/Admin.module.css";
import exit from "../image/admin/admin_exit.svg";
import axios from "axios";
import { useSearchParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react";

export default function AdminPr({setShowAdmin, setRender, render}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const columnk = {notice: "공지사항", advertisement: "언론 및 광고", press: "보도자료", disclosure: "공시자료"}
    const [getData, setData] = useState();
    const [selectItem, setSelectItem] = useState({id: null, column: column,date: '',theme: '',source: '',e_date: '',e_theme: '',e_source: '',link: ''});
    const itemRef = useRef([]);
    const imgRef = useRef();
    const fileRef = useRef();
    const fileRefMo = useRef();
    const fRef = useRef();
    const [uploadFile, setUploadFile] = useState(null);
    const [uploadFileMo, setUploadFileMo] = useState(null);
    const [uploadF, setUploadF] = useState([]);
    const [imageCopy, setImageCopy] = useState(false);

    function closeAdmin() {
        setShowAdmin(false);
    }
    function saveData() {
        if(column !== "disclosure") {
            if(
                itemRef.current[0].value === '' ||
                itemRef.current[1].value === '' ||
                itemRef.current[2].value === '' ||
                itemRef.current[3].value === '' ||
                itemRef.current[4].value === ''
            ) {
                alert("필수 정보를 입력해주세요.")
                return
            }
            if(window.confirm('등록하시겠습니까?')) {
                const newData = JSON.stringify({
                    field: 'pr',
                    column: column,
                    theme: itemRef.current[0].value.replaceAll('\'', '\\\''),
                    e_theme: itemRef.current[1].value.replaceAll('\'', '\\\''),
                    date: itemRef.current[2].value,
                    e_date: itemRef.current[3].value,
                    content: itemRef.current[4].value.replaceAll('\'', '\\\''),
                    e_content: column !== "disclosure" ? itemRef.current[5].value.replaceAll('\'', '\\\'') : '',
                    link: column === "disclosure" ? itemRef.current[4].value.replaceAll('\'', '\\\'') : ''
                });
                const formData = new FormData();
                formData.set('data', newData)
                if(column !== "disclosure") {
                    formData.set('imgFile', uploadFile)
                    uploadF.map((file) => (
                    formData.append('file', file)
                    ))
                    if(imageCopy) {
                        formData.set('imgFileMo', uploadFile)
                    } else {
                        formData.set('imgFileMo', uploadFileMo)
                    }
                }
                apiCallPost(formData);
            }
        } else {
            if(selectItem.id === 0) {
                if(
                    itemRef.current[0].value === '' ||
                    itemRef.current[1].value === '' ||
                    itemRef.current[2].value === '' ||
                    itemRef.current[3].value === '' ||
                    itemRef.current[4].value === ''
                ) {
                    alert("필수 정보를 입력해주세요.")
                    return
                }
                if(window.confirm('등록하시겠습니까?')) {
                    const newData = JSON.stringify({
                        field: 'pr',
                        column: column,
                        theme: itemRef.current[0].value.replaceAll('\'', '\\\''),
                        e_theme: itemRef.current[1].value.replaceAll('\'', '\\\''),
                        date: itemRef.current[2].value,
                        e_date: itemRef.current[3].value,
                        content: itemRef.current[4].value.replaceAll('\'', '\\\''),
                        e_content: column !== "disclosure" ? itemRef.current[5].value.replaceAll('\'', '\\\'') : '',
                        link: column === "disclosure" ? itemRef.current[4].value.replaceAll('\'', '\\\'') : ''
                    });
                    const formData = new FormData();
                    formData.set('data', newData)
                    if(column !== "disclosure") {
                        formData.set('imgFile', uploadFile)
                        uploadF.map((file) => (
                        formData.append('file', file)
                        ))
                        if(imageCopy) {
                            formData.set('imgFileMo', uploadFile)
                        } else {
                            formData.set('imgFileMo', uploadFileMo)
                        }
                    }
                    apiCallPost(formData);
                }
            } else {
                if(
                    itemRef.current[0].value !== selectItem.date.replace(/\r\n/g, '') ||
                    itemRef.current[1].value !== selectItem.theme.replace(/\r\n/g, '') ||
                    itemRef.current[2].value !== selectItem.source.replace(/\r\n/g, '') ||
                    itemRef.current[3].value !== selectItem.e_theme.replace(/\r\n/g, '') ||
                    itemRef.current[4].value !== selectItem.link.replace(/\r\n/g, '')
                ) {
                    if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                        const newData = JSON.stringify({
                            field: 'pr',
                            column: column,
                            id: selectItem.id,
                            date: itemRef.current[0].value,
                            theme: itemRef.current[1].value,
                            source: itemRef.current[2].value,
                            e_theme: itemRef.current[3].value,
                            link: itemRef.current[4].value
                        });
                        const formData = new FormData();
                        formData.set('data', newData)
                        apiCallPut(formData);
                    } else {
                        setUploadFile(null);
                    }
                }
            }
        }
    }

    function fileRefCall(e, id) {
        if (e.target.value.length === 0) {
            return;
        }
        if(id === "f") {
            for(var i=0;i<e.target.files.length;i++) {
                if(e.target.files[0].length > e.target.size) {
                    alert("파일 용량이 20MB를 초과합니다.");
                    return
                }
            }
        } else {
            if(e.target.files[0].size > e.target.size) {
                alert("파일 용량이 10MB를 초과합니다.");
                return
            }
        }
        let reader = new FileReader();

        if(e.target.files[0]) {
            reader.readAsDataURL(e.target.files[0]);
            if(id === "web") {
                setUploadFile(e.target.files[0])
                if(imageCopy) {
                    setUploadFileMo(e.target.files[0])
                }
            } else if(id === "mobile") {
                setUploadFileMo(e.target.files[0])
            } else if(id === "f") {
                if((e.target.files.length + uploadF.length) > 3) {
                    alert('파일 첨부는 최대 3개까지 가능합니다.');
                    return
                } else if(uploadF.length !== 0) {
                    for(var i=0;i<uploadF.length;i++) {
                        for(var j=0;j<Array.from(e.target.files).length;j++) {
                            if(uploadF[i].name === e.target.files[j].name) {
                                alert('중복된 파일이 존재합니다.')
                                return
                            }
                        }
                    }
                }
                setUploadF([...uploadF, ...Array.from(e.target.files)]);
            }
        }

        reader.onloadend = function() {
        }
    }

    function uploadImage(e) {
        var ref = null;
        if(e.currentTarget.id === "web") {
            ref = fileRef.current;
        } else if(e.currentTarget.id === "mobile") {
            if(imageCopy) {
                return
            } else {
                ref = fileRefMo.current;
            }
        } else {
            ref = fRef.current;
        }
        if (!ref) {
            return;
        }
        ref.click();
    }

    function addNewItem() {
        if(selectItem.id === 0) {
            alert('현재 등록중인 데이터가 있습니다.')
            return
        }
        const emptyData = {
            id: 0,
            column: column,
            date: '',
            theme: '',
            source: '',
            e_date: '',
            e_theme: '',
            e_source: '',
            link: ''
        }
        setData([emptyData, ...getData])
        setSelectItem([emptyData, ...getData][0])
    }

    function deleteItem(e, id) {
        e.stopPropagation();
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                setData(getData.filter(data => data.id !== 0))
                setSelectItem(getData.filter(data => data.id !== 0)[0])
            }
            return
        }
        if(window.confirm("정말 삭제하시겠습니까?")) {
            apiCallDelete(id)
        }
    }

    function changeTarget(idx, id) {
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                setData(getData.filter(data => data.id !== 0))
            } else {
                return
            }
        } else {
            if(
                itemRef.current[0].value !== selectItem.date.replace(/\r\n/g, '') ||
                itemRef.current[1].value !== selectItem.theme.replace(/\r\n/g, '') ||
                itemRef.current[2].value !== selectItem.source.replace(/\r\n/g, '') ||
                itemRef.current[3].value !== selectItem.e_theme.replace(/\r\n/g, '') ||
                itemRef.current[4].value !== selectItem.link.replace(/\r\n/g, '')
            ) {
                if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                    const newData = JSON.stringify({
                        field: 'pr',
                        column: column,
                        id: selectItem.id,
                        date: itemRef.current[0].value,
                        theme: itemRef.current[1].value,
                        source: itemRef.current[2].value,
                        e_theme: itemRef.current[3].value,
                        link: itemRef.current[4].value
                    });
                    const formData = new FormData();
                    formData.set('data', newData)
                    apiCallPut(formData);
                } else {
                    setUploadFile(null);
                }
            }
        }
        setSelectItem(getData[idx])
    }

    async function apiCallGet() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER + '/api/data/pr');
            setData(response.data.data.disclosure)
            if(response.data.data[column].length !== 0) {
                setSelectItem(response.data.data[column][0])
            } else {
                setData([{id: null, column: column,date: '',theme: '',source: '',e_date: '',e_theme: '',e_source: '',link: ''}])
                setSelectItem({id: null, column: column,date: '',theme: '',source: '',e_date: '',e_theme: '',e_source: '',link: ''})
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPost(data) {
        try {
            const response = await axios.post(process.env.REACT_APP_SERVER + "/api/admin/add/pr",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );

            if(response.data.state === "success") {
                alert('등록이 완료되었습니다.');
                itemRef.current[0].value = "";
                itemRef.current[1].value = "";
                itemRef.current[2].value = "";
                itemRef.current[3].value = "";
                itemRef.current[4].value = "";
                setUploadFile(null);
                setUploadFileMo(null);
                setUploadF([]);
                if(column === "disclosure") {
                    apiCallGet()
                }
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('등록에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPut(data) {
        try {
            const response = await axios.put(process.env.REACT_APP_SERVER + "/api/admin/put/pr",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response.data.state === "success") {
                alert('변경이 완료되었습니다.');
                apiCallGet();
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('변경에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallDelete(id) {
        try {
            const response = await axios.delete(process.env.REACT_APP_SERVER + "/api/admin/delete/pr", {
                data: {
                    id: id,
                    column: column,
                    img: '',
                    file: []
                }
            });
            if(response.data.state === "success") {
                alert('삭제가 완료되었습니다.');
                apiCallGet();
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('삭제에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    useEffect(() => {
        if(column === "notice") {
            if(imageCopy) {
                if(fileRef !== null && uploadFile !== null) {
                    setUploadFileMo(uploadFile)
                }
            } else {
                setUploadFileMo(null);
                fileRefMo.current.value = "";
            }
        }
    }, [imageCopy])

    useEffect(() => {
        setColumn(searchParams.get("column"));
    }, [searchParams])

    useEffect(() => {
        if(selectItem !== 'undefined' && column === "disclosure") {
            itemRef.current[0].value = selectItem.date;
            itemRef.current[1].value = selectItem.theme;
            itemRef.current[2].value = selectItem.source;
            itemRef.current[3].value = selectItem.e_theme;
            itemRef.current[4].value = selectItem.link;
        }
    }, [selectItem])

    useEffect(() => {
        if(column === "disclosure") {
            apiCallGet()
        }
    }, [])

    return(
        <div className={ `${style.container} ${style.pr}` }>
            <div className={ style.topContainer }>
                <span>{columnk[column]} 게시물 관리</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ `${style.middleContainer} ${style.middlePr}`}>
                {
                    column !== "disclosure" ?
                    <div className={style.prContent}>
                        <span className={style.contentTheme}>{columnk[column]} 등록/수정</span>
                        <hr className={style.mainHr}></hr>
                        <div>
                            <div className={style.prContentContainer}>
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>제목</span>
                                    <span className={style.need}>*필수</span>
                                    <span>- 국문 버전에 보여집니다. 공백 포함 50자 내</span>
                                    <div className={`${style.inputItem} ${style.pr600}`}>
                                        <input type="text" ref={ el => (itemRef.current[0] = el) } style={{width: "568px"}} maxLength="50"></input>
                                    </div>
                                </div>
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>Title</span>
                                    <span className={style.need}>*필수</span>
                                    <span>- 영문 버전에 보여집니다. 공백 포함 50자 내</span>
                                    <div className={`${style.inputItem} ${style.pr600}`}>
                                        <input type="text" ref={ el => (itemRef.current[1] = el) } style={{width: "568px"}} maxLength="50"></input>
                                    </div>
                                </div>
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>날짜</span>
                                    <span className={style.need}>*필수</span>
                                    <span>- 국문 버전에 보여집니다.</span>
                                    <div className={`${style.inputItem} ${style.pr600}`}>
                                        <input type="text" ref={ el => (itemRef.current[2] = el) } style={{width: "568px"}}></input>
                                    </div>
                                </div>
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>Date</span>
                                    <span className={style.need}>*필수</span>
                                    <span>- 영문 버전에 보여집니다.</span>
                                    <div className={`${style.inputItem} ${style.pr600}`}>
                                        <input type="text" ref={ el => (itemRef.current[3] = el) } style={{width: "568px"}}></input>
                                    </div>
                                </div>
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>내용</span>
                                    <span className={style.need}>*필수</span>
                                    <span>- 국문 버전에 보여집니다. 공백 포함 5,000자 내</span>
                                    <div className={`${style.inputItem} ${style.pr600160}`}>
                                        <textarea className={style.textarea128} type="text" ref={ el => (itemRef.current[4] = el) } style={{width: "568px"}} maxLength="5000"></textarea>
                                    </div>
                                </div>
                                <div className={style.prInputItem}>
                                    <span className={style.infoTheme}>Content</span>
                                    <span className={style.need}>*필수</span>
                                    <span>- 영문 버전에 보여집니다. 공백 포함 5,000자 내</span>
                                    <div className={`${style.inputItem} ${style.pr600160}`}>
                                        <textarea className={style.textarea128} type="text" ref={ el => (itemRef.current[5] = el) } style={{width: "568px"}} maxLength="5000"></textarea>
                                    </div>
                                </div>
                            </div>
                            <hr></hr>
                            <div className={style.prFileContainer}>
                                <div className={style.fileSpan}>
                                    <span className={style.infoTheme}>이미지 첨부{column === "notice" ? "(Web)" : null}</span>
                                    <span>- 최대 1장(jpg/png), 가로사이즈 최대 1260px 권장</span>
                                </div>
                                <button id="web" className={style.fileAddBtn} onClick={(e) => uploadImage(e)}>첨부하기</button>
                                <input type="file" id="web" accept="image/*" ref={ fileRef } onChange={ (e) => fileRefCall(e, "web") } size="10485760"></input>
                                {
                                    uploadFile &&
                                    <div className={column === "notice" ? `${style.prUploadFile} ${style.limitLength}` : style.prUploadFile}>
                                        <span>{uploadFile.name}</span>
                                        <div className={style.fileDelBtn} onClick={() => {
                                                setUploadFile(null);
                                                fileRef.current.value = ""
                                                if(imageCopy) {
                                                    setUploadFileMo(null);
                                                    fileRefMo.current.value = ""
                                                }
                                            }}>
                                            <div>
                                                <hr></hr>
                                                <hr></hr>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {
                                    column === "notice" &&
                                    <>
                                        <div className={style.fileSpan}>
                                            <span className={style.infoTheme}>이미지 첨부(Mobile)</span>
                                            <span>웹과 동일하게
                                                <div className={style.checkBoxContainer} onClick={() => setImageCopy(!imageCopy)}>
                                                    <div className={imageCopy ? style.checkBoxInner : null}></div>
                                                </div>
                                            </span>
                                        </div>
                                        <button id="mobile" className={style.fileAddBtn} onClick={(e) => uploadImage(e)}>첨부하기</button>
                                        <input type="file" id="mobile" accept="image/*" ref={ fileRefMo } onChange={ (e) => fileRefCall(e, "mobile") } size="10485760"></input>
                                        {
                                            uploadFileMo &&
                                            <div className={`${style.prUploadFile} ${style.limitLength}`}>
                                                <span>{uploadFileMo.name}</span>
                                                <div className={style.fileDelBtn} onClick={() => {
                                                        setUploadFileMo(null);
                                                        fileRefMo.current.value = ""
                                                        if(imageCopy) {
                                                            setUploadFile(null);
                                                            fileRef.current.value = ""
                                                        }
                                                    }}>
                                                    <div>
                                                        <hr></hr>
                                                        <hr></hr>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>
                                }
                            </div>
                            {
                                column === "notice" &&
                                <div className={style.prFileContainer}>
                                    <div className={style.fileSpan}>
                                        <span className={style.infoTheme}>파일 첨부</span>
                                        <span>- 최대 3개, 1개당 최대 20MB</span>
                                    </div>
                                    <button className={style.fileAddBtn} onClick={(e) => uploadImage(e)}>첨부하기</button>
                                    <input type="file" accept="*/*" ref={fRef} onChange={ (e) => fileRefCall(e, "f") } size="20971520" multiple={true}></input>
                                    {
                                        uploadF.map((item, idx) => (
                                            <div key={idx} className={column === "notice" ? `${style.prUploadFile} ${style.limitLength}` : style.prUploadFile}>
                                                <span>{item.name}</span>
                                                <div className={style.fileDelBtn} onClick={() => {
                                                        setUploadF([...uploadF.filter((initem) => initem.name !== item.name)]);
                                                        fRef.current.value = ""
                                                    }}>
                                                    <div>
                                                        <hr></hr>
                                                        <hr></hr>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    :
                    <>
                        <div className={style.showList}>
                            <span className={style.contentTheme}>{columnk[column]} 목록</span>
                            <hr className={style.mainHr}></hr>
                            <div className={style.listContainer}>
                                {
                                    getData !== undefined &&
                                    getData.map((data, idx) => (
                                        <div key={data.id} className={selectItem.id === data.id ? `${style.listItem} ${style.selected}` : style.listItem} onClick={selectItem.id !== data.id ? () => changeTarget(idx, data.id) : null}>
                                            <span>{idx+1}</span>
                                            <span>{data.theme}</span>
                                            <div className={ style.deleteItemBtn } onClick={ (e) => deleteItem(e, data.id) }>    
                                                <div>
                                                    <hr></hr>
                                                    <hr></hr>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                            <button className={style.itemAddBtn} onClick={addNewItem}>{columnk[column]} 추가</button>
                        </div>
                        <div className={style.showContent}>
                            <span className={style.contentTheme}>{columnk[column]} 등록/수정</span>
                            <hr className={style.mainHr}></hr>
                            <div className={style.contentSeperate}>
                                <div>
                                    <span className={style.infoTheme}>{columnk[column]} 정보</span>
                                    <div className={style.contentContainer}>
                                        <div className={`${style.inputItem} ${style.show455}`}>
                                            <span className={style.inputTheme}>연혁 년도</span>
                                            <span className={style.need}>*필수</span>
                                            <input type="text" ref={ el => (itemRef.current[0] = el) } style={{width: "100%"}}></input>
                                        </div>
                                        <div className={`${style.inputItem} ${style.show455}`}>
                                            <span className={style.inputTheme}>공시게시자</span>
                                            <span className={style.need}>*필수</span>
                                            <input type="text" ref={ el => (itemRef.current[2] = el) } style={{width: "100%"}}></input>
                                        </div>
                                        <div className={`${style.inputItem} ${style.showfull}`}>
                                            <span className={style.inputTheme}>영문 연혁</span>
                                            <span className={style.need}>*필수</span>
                                            <input type="text" ref={ el => (itemRef.current[3] = el) } style={{width: "100%"}}></input>
                                        </div>
                                        <div className={`${style.inputItem} ${style.showfull}`}>
                                            <span className={style.inputTheme}>한글 연혁</span>
                                            <span className={style.need}>*필수</span>
                                            <input type="text" ref={ el => (itemRef.current[1] = el) } style={{width: "100%"}}></input>
                                        </div>
                                        <div className={`${style.inputItem} ${style.showfull}`}>
                                            <span className={style.inputTheme}>연결 링크</span>
                                            <span className={style.need}>*필수</span>
                                            <input type="text" ref={ el => (itemRef.current[4] = el) } style={{width: "100%"}}></input>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                }
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={saveData}>
                    <span>저장하기</span>
                </div>
            </div>
        </div>
    )
}