import { useState, useEffect, useRef } from "react";
import leftArrow from "../image/component/pagination_left.svg";
import rightArrow from "../image/component/pagination_right.svg";
import style from "../css/Component.module.css";

export default function Pagination({ postsPerPage, totalPosts, paginate, currentPage }) {
    const pageNumbers = [];
    const paginationNum = useRef();
    const [divLeft, setDivLeft] = useState(leftCal());

    for (let i=1; i<= Math.ceil(totalPosts / postsPerPage); i++) {
        pageNumbers.push(i);
    }

    function nextPagination(e) {
        if(e.target.id === "left") {
            if(document.body.clientWidth > 1317) {
                setDivLeft(divLeft + 200);
            } else {
                setDivLeft(divLeft + 120);
            }
            paginate(Math.floor(((currentPage + 4) / 5) - 1) * 5);
        } else if(e.target.id === "right") {
            if(document.body.clientWidth > 1317) {
                setDivLeft(divLeft - 200);
            } else {
                setDivLeft(divLeft - 120);
            }
            paginate(Math.floor((currentPage + 4) / 5) * 5 + 1);
        }
    }

    function leftCal() {
        var q = parseInt(currentPage / 5);
        var r = currentPage % 5;
        var l = document.body.clientWidth > 1317 ? -200 : -120;
        if(r === 0) {
            return (q-1)*l
        } else {
            return q*l
        }
    }

    useEffect(() => {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }, [currentPage])

    return(
        <div id={style.container}>
            <img id="left" alt="left-arrow" className={style.arrow} src={ leftArrow } onClick={ nextPagination } style={ divLeft === 0 ? {visibility: "hidden"} : {}}/>
            <div className={style.numWrap}>
                <div ref={ paginationNum } className={style.num} style={{left: divLeft + "px", justifyContent: pageNumbers.length > 5 ? "flex-start" : "center"}}>
                    {
                        pageNumbers.map((number) => (
                            <div key={ number } className={number === currentPage ? `${style.numItem} ${style.select}` : style.numItem } onClick={ () => paginate(number) }>
                                <span>{ number }</span>
                            </div>
                        ))
                    }
                </div>
            </div>
            <img id="right" alt="right-arrow" className={style.arrow} src={ rightArrow } onClick={ nextPagination } style={ (pageNumbers.length < 6) || (divLeft === ((Math.ceil((totalPosts / postsPerPage) / 5) - 1) * (document.body.clientWidth > 1317 ? -200 : -120))) ? {visibility: "hidden"} : {}} />
        </div>
    )
}