import style from "../css/Admin.module.css";
import exit from "../image/admin/admin_exit.svg";
import axios from "axios";
import { useSearchParams } from "react-router-dom"
import { useEffect, useRef, useState } from "react";

export default function AdminVideo({setShowAdmin, setRender}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const columnk = {producer: "프로듀서", work: "작품"}
    const [getData, setData] = useState();
    const [selectItem, setSelectItem] = useState(column !== "producer" ?
    {id: null, img: null, theme: null, e_theme: null, broadcaster: null, year: null}
    :
    {id: null, works: null, name: null, position: null, e_works: null, e_name: null, e_position: null});
    const itemRef = useRef([]);
    const imgRef = useRef();
    const fileRef = useRef();
    const [uploadFile, setUploadFile] = useState(null);

    function closeAdmin() {
        setShowAdmin(false);
    }

    function addNewItem() {
        if(selectItem.id === 0) {
            alert('현재 등록중인 데이터가 있습니다.')
            return
        }
        const emptyData = column !== "producer" ? 
        {
            id: 0,
            column: column,
            theme: null,
            e_theme: null,
            broadcaster: null,
            year: null,
            img: ''
        }
        :
        {
            id: 0,
            column: column,
            works: null,
            name: null,
            position: null,
            e_works: null,
            e_name: null,
            e_position: null
        }
        setUploadFile(null);
        setData([emptyData, ...getData])
        setSelectItem([emptyData, ...getData][0])
    }

    function deleteItem(e, id) {
        e.stopPropagation();
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                setData(getData.filter(data => data.id !== 0))
                setSelectItem(getData.filter(data => data.id !== 0)[0])
            }
            return
        }
        if(window.confirm("정말 삭제하시겠습니까?")) {
            apiCallDelete(id)
        }
    }

    function changeTarget(idx, id) {
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                setData(getData.filter(data => data.id !== 0))
            } else {
                return
            }
        } else {
            if(column !== "producer") {
                if(
                    Number(itemRef.current[0].value) !== selectItem.year ||
                    itemRef.current[1].value !== selectItem.broadcaster ||
                    itemRef.current[2].value !== selectItem.theme ||
                    itemRef.current[3].value !== selectItem.e_theme ||
                    imgRef.current.src !== selectItem.img
                ) {
                    if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                        const newData = JSON.stringify({
                            field: 'video',
                            column: column,
                            id: selectItem.id,
                            year: itemRef.current[0].value,
                            broadcaster: itemRef.current[1].value,
                            theme: itemRef.current[2].value.replaceAll('\'', '\\\''),
                            e_theme: itemRef.current[3].value.replaceAll('\'', '\\\''),
                            origin_img: selectItem.img,
                            uploading: uploadFile !== null ? "y" : "n"
                        });
                        const formData = new FormData();
                        formData.set('imgFile', uploadFile)
                        formData.set('data', newData)
                        apiCallPut(formData);
                    } else {
                        setUploadFile(null);
                    }
                }
            } else {
                if(
                    itemRef.current[0].value !== selectItem.name ||
                    itemRef.current[1].value !== selectItem.position ||
                    itemRef.current[2].value !== selectItem.works.replaceAll('/ ', '\n') ||
                    itemRef.current[3].value !== selectItem.e_name ||
                    itemRef.current[4].value !== selectItem.e_position ||
                    itemRef.current[5].value !== selectItem.e_works.replaceAll('/ ', '\n')
                ) {
                    if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                        const newData = JSON.stringify({
                            field: 'video',
                            column: column,
                            id: selectItem.id,
                            name: itemRef.current[0].value,
                            position: itemRef.current[1].value,
                            works: itemRef.current[2].value.replaceAll('\n', '/ '),
                            e_name: itemRef.current[3].value,
                            e_position: itemRef.current[4].value,
                            e_works: itemRef.current[5].value.replaceAll('\n', '/ ')
                        });
                        const formData = new FormData();
                        formData.set('data', newData)
                        apiCallPut(formData);
                    } else {
                        setUploadFile(null);
                    }
                }
            }
        }
        setSelectItem(getData[idx])
    }

    function saveData() {
        if(selectItem.id === 0 && uploadFile === null && column !== "producer") {
            alert('이미지를 등록해주세요.')
            return
        }
        if(selectItem.id === 0) {
            if(window.confirm('등록하시겠습니까?')) {
                const newData = JSON.stringify(column !== "producer" ?
                    {
                        field: 'video',
                        column: column,
                        year: itemRef.current[0].value,
                        theme: itemRef.current[2].value.replaceAll('\'', '\\\''),
                        e_theme: itemRef.current[3].value.replaceAll('\'', '\\\''),
                        broadcaster: itemRef.current[1].value
                    }
                    :
                    {
                        field: 'video',
                        column: column,
                        name: itemRef.current[0].value,
                        position: itemRef.current[1].value,
                        works: itemRef.current[2].value.replaceAll('\n', '/ '),
                        e_name: itemRef.current[3].value,
                        e_position: itemRef.current[4].value,
                        e_works: itemRef.current[5].value.replaceAll('\n', '/ ')
                    }
                );
                const formData = new FormData();
                formData.set('data', newData)
                formData.set('imgFile', uploadFile)
                apiCallPost(formData);
            }
        } else {
            if(column !== "producer") {
                if(
                    Number(itemRef.current[0].value) !== selectItem.year ||
                    itemRef.current[1].value !== selectItem.broadcaster ||
                    itemRef.current[2].value !== selectItem.theme ||
                    itemRef.current[3].value !== selectItem.e_theme ||
                    imgRef.current.src !== selectItem.img
                ) {
                    if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                        const newData = JSON.stringify({
                            field: 'video',
                            column: column,
                            id: selectItem.id,
                            year: itemRef.current[0].value,
                            broadcaster: itemRef.current[1].value,
                            theme: itemRef.current[2].value.replaceAll('\'', '\\\''),
                            e_theme: itemRef.current[3].value.replaceAll('\'', '\\\''),
                            origin_img: selectItem.img,
                            uploading: uploadFile !== null ? "y" : "n"
                        });
                        const formData = new FormData();
                        formData.set('imgFile', uploadFile)
                        formData.set('data', newData)
                        apiCallPut(formData);
                    } else {
                        setUploadFile(null);
                    }
                }
            } else {
                if(
                    itemRef.current[0].value !== selectItem.name ||
                    itemRef.current[1].value !== selectItem.position ||
                    itemRef.current[2].value !== selectItem.works.replaceAll('/ ', '\n') ||
                    itemRef.current[3].value !== selectItem.e_name ||
                    itemRef.current[4].value !== selectItem.e_position ||
                    itemRef.current[5].value !== selectItem.e_works.replaceAll('/ ', '\n')
                ) {
                    if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                        const newData = JSON.stringify({
                            field: 'video',
                            column: column,
                            id: selectItem.id,
                            name: itemRef.current[0].value,
                            position: itemRef.current[1].value,
                            works: itemRef.current[2].value.replaceAll('\n', '/ '),
                            e_name: itemRef.current[3].value,
                            e_position: itemRef.current[4].value,
                            e_works: itemRef.current[5].value.replaceAll('\n', '/ ')
                        });
                        const formData = new FormData();
                        formData.set('data', newData)
                        apiCallPut(formData);
                    } else {
                        setUploadFile(null);
                    }
                }
            }
        }
    }

    function fileRefCall(e) {
        if (e.target.value.length === 0) {
            return;
        } else if(e.target.files[0].size > e.target.size) {
            alert("파일 용량이 10MB를 초과합니다.");
            return
        } else {
            let reader = new FileReader();

            if(e.target.files[0]) {
                reader.readAsDataURL(e.target.files[0]);
                setUploadFile(e.target.files[0])
            }

            reader.onloadend = function() {
                imgRef.current.src = reader.result;
            }
        }
    }

    function uploadImage() {
        if (!fileRef.current) {
            return;
        }
        fileRef.current.click();
    }

    async function apiCallGet() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/video');
            setData(response.data.data[column])
            if(response.data.data[column].length !== 0) {
                setSelectItem(response.data.data[column][0])
            } else {
                setData([column !== "producer" ?
                    {id: 0,column: column,year: null,theme: '',e_theme: '',broadcaster: '',img: ''}
                    :
                    {id: 0,column: column,works: null,name: null,position: null,e_works: null,e_name: null,e_position: null}
                ])
                setSelectItem(column !== "producer" ?
                    {id: 0,column: column,year: null,theme: '',e_theme: '',broadcaster: '',img: ''}
                    :
                    {id: 0,column: column,works: null,name: null,position: null,e_works: null,e_name: null,e_position: null}
                )
            }

        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPost(data) {
        try {
            const response = await axios.post(process.env.REACT_APP_SERVER + "/api/admin/add/video",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response.data.state === "success") {
                alert('등록이 완료되었습니다.');
                apiCallGet();
                setUploadFile(null);
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('등록에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPut(data) {
        try {
            const response = await axios.put(process.env.REACT_APP_SERVER + "/api/admin/put/video",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response.data.state === "success") {
                alert('변경이 완료되었습니다.');
                apiCallGet();
                setUploadFile(null);
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('변경에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallDelete(id) {
        try {
            const response = await axios.delete(process.env.REACT_APP_SERVER + "/api/admin/delete/video", {
                data: column !== "producer" ?
                    {
                        id: id,
                        column: column,
                        img: getData.filter(data => data.id === id)[0]['img']
                    }
                :
                    {
                        id: id,
                        column: column,
                    }

            });
            if(response.data.state === "success") {
                alert('삭제가 완료되었습니다.');
                apiCallGet();
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('삭제에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    useEffect(() => {
        setColumn(searchParams.get("column"));
    }, [searchParams])

    useEffect(() => {
        apiCallGet()
    }, [])

    useEffect(() => {
        if(column !== "producer") {
            if(selectItem !== 'undefined') {
                itemRef.current[0].value = selectItem.year;
                itemRef.current[1].value = selectItem.broadcaster;
                itemRef.current[2].value = selectItem.theme;
                itemRef.current[3].value = selectItem.e_theme;
                imgRef.current.src = selectItem.img;
                if(selectItem.img !== '' && selectItem.img !== null) {
                    setUploadFile({name: selectItem.img.split('/video_' + column + '/')[1]})
                    console.log(selectItem.img.split('/video_' + column + '/')[1])
                }
            }
        } else {
            if(selectItem !== 'undefined') {
                itemRef.current[0].value = selectItem.name;
                itemRef.current[1].value = selectItem.position;
                itemRef.current[2].value = selectItem.works !== null ? selectItem.works.replaceAll('/ ', '\n') : null;
                itemRef.current[3].value = selectItem.e_name;
                itemRef.current[4].value = selectItem.e_position;
                itemRef.current[5].value = selectItem.e_works !== null ? selectItem.e_works.replaceAll('/ ', '\n') : null;
            }
        }
    }, [selectItem])

    return(
        <div className={ `${style.container} ${style.show}` }>
            <div className={ style.topContainer }>
                <span>{columnk[column]} 관리</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ `${style.middleContainer} ${style.middleShow}`}>
                <div className={style.showList}>
                    <span className={style.contentTheme}>{columnk[column]} 목록</span>
                    <hr className={style.mainHr}></hr>
                    <div className={style.listContainer}>
                        {
                            getData !== undefined &&
                            getData.map((data, idx) => (
                                <div key={data.id} className={selectItem.id === data.id ? `${style.listItem} ${style.selected}` : style.listItem} onClick={selectItem.id !== data.id ? () => changeTarget(idx, data.id) : null}>
                                    <span>{idx+1}</span>
                                    <span>{column !== "producer" ? data.theme : data.name}</span>
                                    <div className={ style.deleteItemBtn } onClick={ (e) => deleteItem(e, data.id) }>
                                        <div>
                                            <hr></hr>
                                            <hr></hr>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <button className={style.itemAddBtn} onClick={addNewItem}>{columnk[column]} 추가</button>
                </div>
                <div className={style.showContent}>
                    <span className={style.contentTheme}>{columnk[column]} 등록/수정</span>
                    <hr className={style.mainHr}></hr>
                    {
                        column !== "producer" ?
                        <div className={style.contentSeperate}>
                            <div className={style.contentLeft}>
                                <span className={style.infoTheme}>{columnk[column]} 정보</span>
                                <div className={style.contentContainer}>
                                    <div className={`${style.inputItem} ${style.show400}`}>
                                        <span className={style.inputTheme}>방영년도</span>
                                        <span className={style.need}>*필수</span>
                                        <input type="text" ref={ el => (itemRef.current[0] = el) } style={{width: "289px"}}></input>
                                    </div>
                                    <div className={`${style.inputItem} ${style.show400}`}>
                                        <span className={style.inputTheme}>영문방영사</span>
                                        <input type="text" ref={ el => (itemRef.current[1] = el) } style={{width: "240px"}}></input>
                                    </div>
                                    <hr></hr>
                                    <div className={`${style.inputItem} ${style.show400}`}>
                                        <span className={style.inputTheme}>한글제목</span>
                                        <span className={style.need}>*필수</span>
                                        <input type="text" ref={ el => (itemRef.current[2] = el) } style={{width: "240px"}}></input>
                                    </div>
                                    <div className={`${style.inputItem} ${style.show400}`}>
                                        <span className={style.inputTheme}>영문제목</span>
                                        <span className={style.need}>*필수</span>
                                        <input type="text" ref={ el => (itemRef.current[3] = el) } style={{width: "289px"}}></input>
                                    </div>
                                </div>
                            </div>
                            <div className={style.contentRight}>
                                <span className={style.infoTheme}>{columnk[column]} 이미지</span>
                                <span className={style.need}>*필수</span>
                                <span className={style.uploadSpan}>- 10mb 미만의 이미지만 업로드할 수 있습니다.</span>
                                <div className={style.imgContainer} onClick={(e) => uploadImage(e)}>
                                    <div className={ style.imgIntroContainer }>
                                        <div className={style.plusShape2}>
                                            <hr></hr>
                                            <hr></hr>
                                        </div>
                                        <span className={style.introMent2}>{"465*310"}px 이상의 이미지 업로드를 권장합니다. (다른 비율로 업로드 시 이미지 비율 훼손)</span>
                                    </div>
                                    <img ref={imgRef} src="" alt="" onError={(e) => e.target.style = style.none}></img>
                                    <input type="file" accept="image/*" ref={ fileRef } onChange={ (e) => fileRefCall(e) } size="10485760"></input>
                                </div>
                            </div>
                        </div>
                        :
                        <div className={style.contentDouble}>
                            <span className={style.infoTheme}>{columnk[column]} 정보</span>
                            <div className={style.contentContainer}>
                                <div className={`${style.inputItem} ${style.show455}`}>
                                    <span className={style.inputTheme}>한글이름</span>
                                    <span className={style.need}>*필수</span>
                                    <input type="text" ref={ el => (itemRef.current[0] = el) } style={{width: "289px"}}></input>
                                </div>
                                <div className={`${style.inputItem} ${style.show455}`}>
                                    <span className={style.inputTheme}>영문이름</span>
                                    <span className={style.need}>*필수</span>
                                    <input type="text" ref={ el => (itemRef.current[3] = el) } style={{width: "240px"}}></input>
                                </div>
                                <div className={`${style.inputItem} ${style.show455}`}>
                                    <span className={style.inputTheme}>한글직책</span>
                                    <span className={style.need}>*필수</span>
                                    <input type="text" ref={ el => (itemRef.current[1] = el) } style={{width: "240px"}}></input>
                                </div>
                                <div className={`${style.inputItem} ${style.show455}`}>
                                    <span className={style.inputTheme}>영문직책</span>
                                    <span className={style.need}>*필수</span>
                                    <input type="text" ref={ el => (itemRef.current[4] = el) } style={{width: "240px"}}></input>
                                </div>
                                <div className={`${style.videoArea} ${style.show455}`}>
                                    <span className={style.inputTheme}>한글작품</span>
                                    <span className={style.need}>*필수</span>
                                    <textarea ref={ el => (itemRef.current[2] = el) }></textarea>
                                </div>
                                <div className={`${style.videoArea} ${style.show455}`}>
                                    <span className={style.inputTheme}>영문작품</span>
                                    <span className={style.need}>*필수</span>
                                    <textarea ref={ el => (itemRef.current[5] = el) }></textarea>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={saveData}>
                    <span>저장하기</span>
                </div>
            </div>
        </div>
    )
}