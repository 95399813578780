import { useSearchParams } from "react-router-dom"
import style from "../css/Food.module.css"
import { useCallback, useEffect, useRef, useState } from "react";
import SEOMetaTag from "../Components/SEOMetaTag";
import foodBrandName from "../image/food/food_brand.png"
import foodBrandNameEn from "../image/food/food_brand_en.svg"
import brand1 from "../image/food/brand_1.png"
import brand2 from "../image/food/brand_2.png"

export default function Food({isEn}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const circleRef = useRef();
    const hrRef = useRef();
    const contentRef = useRef();
    const columnData = ["kkotmareum"];
    const columnKorData = ["꽃마름"];
    const columnEnData = ["Kkotmareum"];
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    const [autoHeight, setAutoHeight] = useState([0]);

    function changeColumn(column) {
        searchParams.set("column", column)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(document.body.clientWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(document.body.clientWidth);
            });
        }
    }, [])

    useEffect(() => {
        setColumn(searchParams.get("column"));
        t()
        switch (columnData.indexOf(searchParams.get("column"))) {
            case 0:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
            default:
                setColumn("kkotmareum");
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + "px";
                return
        }
    }, [searchParams, screenWidth, autoHeight])

    function t(e) {
        if(JSON.stringify(autoHeight) !== JSON.stringify([
            contentRef.current.childNodes[0].clientHeight
        ])) {
            setAutoHeight([
                contentRef.current.childNodes[0].clientHeight
            ]);
        }
    }

    return(
        <div className={style.wrap} onLoad={t}>
            <SEOMetaTag title="외식사업부" description="한주에이알티 주식회사 외식사업부 페이지" url="https://hanjooart.com/food"></SEOMetaTag>
            <div className={style.topContainer}>
                <span>{isEn ? "Food Service Dept." : "외식사업부"}</span>
                <div ref={hrRef} className={style.columnContainer}>
                    {
                        columnData.map((data, idx) => (
                            <span key={idx} onClick={() => changeColumn(data)} className={data === column ? `${style.columnContainerItem} ${style.selected}` : style.columnContainerItem}>{isEn ? columnEnData[idx] : columnKorData[idx]}</span>
                        ))
                    }
                </div>
                <div ref={circleRef} className={style.hrCircle}></div>
            </div>
            <div className={style.bottomContainer}>
                <div ref={contentRef} className={style.bottomContainerWrap}>
                    <div key={Math.random()} className={style.contentContainer}>
                        <div className={style.brandItem}>
                            <div className={style.brandName}>
                                <img src={isEn ? foodBrandNameEn : foodBrandName} alt="brand-logo"></img>
                                <div className={style.brandLinkContainer}>
                                    <span>{isEn ? "Enter Partners operates a restaurant brand." : "한주에이알티 주식회사는 외식브랜드 ‘꽃마름’을 운영합니다."}</span>
                                    <button className={style.brandLinkBtn} onClick={() => window.open("https://www.kkmr.co.kr/")}>{isEn ? "Go" : "바로가기"}</button>
                                </div>
                            </div>
                            <img src={brand1} className={style.brandImg} alt="brand-img-1"></img>
                            <div className={style.brandContentContainer}>
                                <span>{isEn ? "Kkotmareum started with the opening of the first store in 2010.\nIt has about 56 stores nationwide." : "꽃마름은 2010년 1호점 오픈을 시작으로\n전국 약 56개의 매장을 보유하고 있습니다."}</span>
                            </div>
                            <img src={brand2} className={style.brandImg} alt="brand-img-2"></img>
                            <div className={style.brandContentContainer}>
                                <span>{isEn ? "HANJOO A.R.T. puts the customer’s health first\nand will approach customers with the wishes of customer\nsatisfaction and happiness." : "한주에이알티 주식회사는 고객의 건강을 최우선으로 생각하며\n고객 만족, 고객 감동, 행복의 기원을 담아\n고객님들의 곁으로 다가갈 것입니다."}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}