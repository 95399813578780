import { Helmet } from "react-helmet";

export default function SEOMetaTag(props) {
    return(
        <Helmet>
            <title>{props.title}</title>
            <meta name="description" content={props.description}></meta>
            <meta name="keywords" content="한주에이알티, hanjooart, 경남제약"></meta>

            <meta property="og:type" content="website"></meta>
            <meta property="og:title" content={props.title}></meta>
            <meta property="og:site_name" content="한주에이알티 주식회사"></meta>
            <meta property="og:description" content={props.description}></meta>
            <meta property="og:url" content={props.url}></meta>

            <link rel="canonical" href={props.url}></link>
        </Helmet>
    )
}