import style from "../css/Admin.module.css";
import exit from "../image/admin/admin_exit.svg";
import axios from "axios";
import { useEffect, useRef, useState } from "react";

export default function AdminAbout({setShowAdmin, setRender}) {
    const [getData, setData] = useState();
    const [selectItem, setSelectItem] = useState({id: null, year: null, month: null, content: null, e_content: null});
    const itemRef = useRef([]);

    function closeAdmin() {
        setShowAdmin(false)
    }

    function addNewItem() {
        if(selectItem[0].id === 0) {
            alert('현재 등록중인 데이터가 있습니다.')
            return
        }
        const emptyData = {
            id: 0,
            year: null,
            month: null,
            content: '',
            e_content: ''
        }
        setData([emptyData, ...getData])
        setSelectItem([emptyData, ...getData][0])
    }

    function deleteItem(e, id) {
        e.stopPropagation();
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                setData(getData.filter(data => data.id !== 0))
                setSelectItem(getData.filter(data => data.id !== 0)[0])
            }
            return
        }
        if(window.confirm("정말 삭제하시겠습니까?")) {
            apiCallDelete(id)
        }
    }

    function changeTarget(idx, id) {
        if(selectItem.id === 0) {
            if(window.confirm("등록을 취소하시겠습니까?")) {
                setData(getData.filter(data => data.id !== 0))
            } else {
                return
            }
        } else {
            if(
                Number(itemRef.current[0].value) !== selectItem.year ||
                itemRef.current[1].value !== selectItem.content ||
                Number(itemRef.current[2].value) !== selectItem.month ||
                itemRef.current[3].value !== selectItem.e_content
            ) {
                if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                    const newData = JSON.stringify({
                        field: 'about',
                        column: 'history',
                        id: selectItem.id,
                        year: itemRef.current[0].value,
                        month: itemRef.current[2].value,
                        content: itemRef.current[1].value.replaceAll('\'', '\\\''),
                        e_content: itemRef.current[3].value.replaceAll('\'', '\\\'')
                    });
                    const formData = new FormData();
                    formData.set('data', newData)
                    apiCallPut(formData);
                }
            }
        }
        setSelectItem(getData[idx])
    }

    function saveData() {
        if(selectItem.id === 0) {
            if(window.confirm('등록하시겠습니까?')) {
                const newData = JSON.stringify({
                    field: 'about',
                    column: 'history',
                    year: itemRef.current[0].value,
                    month: itemRef.current[2].value,
                    content: itemRef.current[1].value.replaceAll('\'', '\\\''),
                    e_content: itemRef.current[3].value.replaceAll('\'', '\\\'')
                });
                const formData = new FormData();
                formData.set('data', newData)
                apiCallPost(formData);
            }
        } else {
            if(
                Number(itemRef.current[0].value) !== selectItem.year ||
                itemRef.current[1].value !== selectItem.content ||
                Number(itemRef.current[2].value) !== selectItem.month ||
                itemRef.current[3].value !== selectItem.e_content
            ) {
                if(window.confirm('변경된 내용을 저장하시겠습니까?')) {
                    const newData = JSON.stringify({
                        field: 'about',
                        column: 'history',
                        id: selectItem.id,
                        year: itemRef.current[0].value,
                        month: itemRef.current[2].value,
                        content: itemRef.current[1].value.replaceAll('\'', '\\\''),
                        e_content: itemRef.current[3].value.replaceAll('\'', '\\\'')
                    });
                    const formData = new FormData();
                    formData.set('data', newData)
                    apiCallPut(formData);
                }
            }
        }
    }

    async function apiCallGet() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/about_history');
            setData(response.data.data.about)
            if(response.data.data.about.length !== 0) {
                setSelectItem(response.data.data.about[0])
            } else {
                setData([{id: 0,year: null,month: null,content: '',e_content: ''}])
                setSelectItem({id: 0,year: null,month: null,content: '',e_content: ''})
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPost(data) {
        try {
            const response = await axios.post(process.env.REACT_APP_SERVER + "/api/admin/add/about",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response.data.state === "success") {
                alert('등록이 완료되었습니다.');
                apiCallGet();
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('등록에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallPut(data) {
        try {
            const response = await axios.put(process.env.REACT_APP_SERVER + "/api/admin/put/about",
                data,
                {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }
            );
            if(response.data.state === "success") {
                alert('변경이 완료되었습니다.');
                apiCallGet();
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('변경에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallDelete(id) {
        try {
            const response = await axios.delete(process.env.REACT_APP_SERVER + "/api/admin/delete/about", {
                data: {
                    id: id
                }
            });
            if(response.data.state === "success") {
                alert('삭제가 완료되었습니다.');
                apiCallGet();
            } else {
                if(response.data.errmsg !== undefined) {
                    alert(response.data.errmsg)
                } else {
                    alert('삭제에 실패하였습니다.')
                }
            }
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    useEffect(() => {
        apiCallGet()
    }, [])

    useEffect(() => {
        if(selectItem !== 'undefined') {
            itemRef.current[0].value = selectItem.year;
            itemRef.current[1].value = selectItem.content;
            itemRef.current[2].value = selectItem.month;
            itemRef.current[3].value = selectItem.e_content;
        }
    }, [selectItem])

    return(
        <div className={ `${style.container} ${style.about}` }>
            <div className={ style.topContainer }>
                <span>연혁 관리</span>
                <img src={ exit } alt="exit" onClick={ closeAdmin }></img>
            </div>
            <div className={ `${style.middleContainer} ${style.middleAbout}`}>
                <div className={style.aboutList}>
                    <span className={style.contentTheme}>연혁 목록</span>
                    <hr className={style.mainHr}></hr>
                    <div className={style.listContainer}>
                        {
                            getData !== undefined &&
                            getData.map((data, idx) => (
                                <div key={data.id} className={selectItem.id === data.id ? `${style.listItem} ${style.selected}` : style.listItem} onClick={selectItem.id !== data.id ? () => changeTarget(idx, data.id) : null}>
                                    <span>{idx+1}</span>
                                    <span>{data.content}</span>
                                    <div className={ style.deleteItemBtn } onClick={ (e) => deleteItem(e, data.id) }>
                                        <div>
                                            <hr></hr>
                                            <hr></hr>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    <button className={style.itemAddBtn} onClick={addNewItem}>연혁 추가</button>
                </div>
                <div className={style.aboutContent}>
                    <span className={style.contentTheme}>연혁 등록/수정</span>
                    <hr className={style.mainHr}></hr>
                    <span className={style.infoTheme}>연혁 정보</span>
                    <div className={style.contentContainer}>
                        <div className={`${style.inputItem} ${style.about200}`}>
                            <span className={style.inputTheme}>연혁 연도</span>
                            <span className={style.need}>*필수</span>
                            <input type="number" ref={ el => (itemRef.current[0] = el) } style={{width: "69px"}}></input>
                        </div>
                        <div className={`${style.inputItem} ${style.about710}`}>
                            <span className={style.inputTheme}>한글 연혁</span>
                            <span className={style.need}>*필수</span>
                            <input type="text" ref={ el => (itemRef.current[1] = el) } style={{width: "579px"}}></input>
                        </div>
                        <div className={`${style.inputItem} ${style.about200}`}>
                            <span className={style.inputTheme}>연혁 달</span>
                            <span className={style.need}>*필수</span>
                            <input type="number" ref={ el => (itemRef.current[2] = el) } style={{width: "83px"}}></input>
                        </div>
                        <div className={`${style.inputItem} ${style.about710}`}>
                            <span className={style.inputTheme}>영문 연혁</span>
                            <span className={style.need}>*필수</span>
                            <input type="text" ref={ el => (itemRef.current[3] = el) } style={{width: "579px"}}></input>
                        </div>
                    </div>
                </div>
            </div>
            <div className={ style.bottomContainer }>
                <div className={ style.actionButton } onClick={saveData}>
                    <span>저장하기</span>
                </div>
            </div>
        </div>
    )
}