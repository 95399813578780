import { useState, useRef, useEffect } from 'react';
import style from "../css/Main.module.css";
import mainAbout from "../image/main/main_about.png"
import mainSub1 from "../image/main/main_sub_1.png"
import mainSub2 from "../image/main/main_sub_2.png"
import mainSub3 from "../image/main/main_sub_3.png"
import mainSub4 from "../image/main/main_sub_4.png"
import mainSub5 from "../image/main/main_sub_5.png"
import mainSub1M from "../image/main/main_sub_1_m.png"
import mainSub2M from "../image/main/main_sub_2_m.png"
import mainSub3M from "../image/main/main_sub_3_m.png"
import mainSub4M from "../image/main/main_sub_4_m.png"
import mainSub5M from "../image/main/main_sub_5_m.png"
import mainLogo from "../image/component/logo_column-white.svg"
import mainLogo2 from "../image/component/logo_column.svg"
import mainLogoE from "../image/component/logoE_column-white.svg"
import mainLogoE2 from "../image/component/logoE_column.svg"
import right from '../image/main/right_btn.svg'
import left from '../image/main/left_btn.svg'
import { NavLink } from 'react-router-dom';
import SEOMetaTag from '../Components/SEOMetaTag';
import axios from 'axios';
import Portal from '../Components/Portal';
import AdminModal from '../AdminComponents/AdminModal';
import Popup from '../Components/Popup';

export default function Main({isEn, admin, setAdmin, showAdmin, setShowAdmin, adminField, setAdminField, render, setRender}) {
    const [getData, setData] = useState();
    const [isShow, setIsShow] = useState(1);
    const [startLeft, setStartLeft] = useState();
    const [workShowNum, setWorkShowNum] = useState(0);
    const [cloneShowNum, setCloneShowNum] = useState();
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    const [btnState, setBtnState] = useState(true);

    const sliderRef = useRef();
    const workslideRef = useRef();
    const workCloneRef = useRef();
    const timeoutRef = useRef(null);
    const timeoutRef2 = useRef(null);

    const mainData = [
        // {id: 1, img: mainSub3, img_m: mainSub3M, theme: "공연사업부", link: "show?column=concert", eTheme: "Performance Dept.", content: "차별화된 제작 역량과 프로듀싱으로 트렌드를 선도하고 K-POP의 영향력을 더욱 확대합니다.", eContent:"Leading the trend with differentiated production capabilities and productionExpanding the influence of K-POP."},
        {id: 2, img: mainSub2, img_m: mainSub2M, theme: "미디어사업부", link: "video?column=overview", eTheme: "Media Business Dept.", content: "모두가 하나 되어 공감할 수 있고 재미와 감동이 있는 다양한 장르의 콘텐츠를 기획·제작합니다.", eContent:"Everyone can sympathize as one and have fun and excitement We plan and produce content of various genres."},
        {id: 3, img: mainSub4, img_m: mainSub4M, theme: "외식사업부", link: "food?column=brand", eTheme: "Food Service Dept.", content: "신선하고 건강한 식재료를 통해 보다 맛있게 소통하는 방법을 제시합니다.", eContent:"Through fresh and healthy ingredients We suggest a way to communicate more deliciously."},
        {id: 4, img: mainSub1, img_m: mainSub1M, theme: "엔터사업부", link: "enter?column=enter", eTheme: "Entertainment Dept.", content: "아티스트가 가진 매력을 이끌어 내고 다양한 분야에서 대중에게 다가갈 수 있도록 합니다.", eContent:"Bring out the charm of the artist and work in various fields make it accessible to the public."},
        {id: 5, img: mainSub5, img_m: mainSub5M, theme: "SF Steel", link: "sfsteel?column=eoutline", eTheme: "SF Steel", content: "무한재생이 가능한 순환자원을 바탕으로 친환경 경영을 이어갑니다.", eContent:"We sustain eco-friendly management with infinite circulation of renewable resources."},
    ]
    const infoData = [
        {id: 1, content: "12F, 21, Godeokbizvalley-ro 2ga-gil, Gangdong-gu, Seoul, Korea"},
        {id: 2, content: "T. 1522-0011"},
    ]
    const [workSlide, setWorkSlide] = useState(null);
    const [popup, setPopup] = useState(false);
    const VISIT_BEFORE = Number(localStorage.getItem('VisitCookie'));
    const VISIT_NOW = new Date().getDate();

    function changeSlide(e) {
        const a = Number(e.target.id);
        setIsShow(a);
        sliderRef.current.style.left = -1 * window.innerWidth * (a-1) + "px";
    }

    function changeSlide2(e) {
        if(e.target.id === "sleft") {
            if(btnState) {
                setBtnState(false);
                autoSlide("r")
            }
        } else if(e.target.id === "sright") {
            if(btnState) {
                setBtnState(false);
                autoSlide("l")
            }
        }
    }

    function resetTimer() {
        if(timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }
    }

    function resetTimer2() {
        if(timeoutRef2.current) {
            clearTimeout(timeoutRef2.current);
        }
    }

    function autoSlide(direction) {
        let itemWidth = workslideRef.current.children[0].clientWidth + Number(window.getComputedStyle(workslideRef.current).gap.split(' ')[0].replace("px", ""));
        let cntW, cntC;

        workCloneRef.current.style.transition = "all 0.3s ease-out";
        if(direction === "l") {
            setWorkShowNum(workShowNum - 1);
            setCloneShowNum(cloneShowNum - 1);
            cntW = workShowNum - 1;
            cntC = cloneShowNum - 1;
        } else if(direction === "r") {
            setWorkShowNum(workShowNum + 1);
            setCloneShowNum(cloneShowNum + 1);
            cntW = workShowNum + 1;
            cntC = cloneShowNum + 1;
        }
        workslideRef.current.style.left = startLeft + (itemWidth * cntW) + "px";
        workCloneRef.current.style.left = startLeft + (itemWidth * cntC) + "px";

        if(cntC < -1 * (workSlide.length + 3)) {
            workCloneRef.current.style.visibility = "hidden";
            setCloneShowNum(workShowNum + workSlide.length - 1);
        }

        if((0 < cntC && cntC < 7) || (cntC > -15 && cntC < -13)) {
            workCloneRef.current.style.visibility = "visible";
        }
        if(cntC > 7) {
            workCloneRef.current.style.visibility = "hidden";
            setCloneShowNum(workShowNum - (workSlide.length - 1));
        }


        if(cntW < -1 * (workSlide.length + 3)) {
            workslideRef.current.style.visibility = "hidden";
            setWorkShowNum(cloneShowNum + workSlide.length - 1);
        }

        if((0 < cntW && cntW < 7) || (cntW > -15 && cntW < -13)) {
            workslideRef.current.style.visibility = "visible";
        }
        if(cntW > 7) {
            workslideRef.current.style.visibility = "hidden";
            setWorkShowNum(cloneShowNum - (workSlide.length - 1));
        }
    }

    async function apiCall() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/main');
            setData(response.data.data)
            const response2 = await axios.get(process.env.REACT_APP_SERVER +'/api/data/video');
            setStartLeft(Number(window.getComputedStyle(workslideRef.current).left.replace("px", "")))
            setWorkSlide(response2.data.data.work);
            setCloneShowNum(-1 * response2.data.data.work.length)
            workCloneRef.current.style.left = -1 * (workslideRef.current.children[0].clientWidth + Number(window.getComputedStyle(workslideRef.current).gap.split(' ')[0].replace("px", ""))) * response2.data.data.work.length + Number(window.getComputedStyle(workslideRef.current).left.replace("px", "")) + "px"
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    useEffect(() => {
        apiCall();
        window.addEventListener('resize', () => {
            setScreenWidth(document.body.clientWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(document.body.clientWidth);
            });
        }
    }, []);

    useEffect(() => {
        if(!btnState) {
            setTimeout(() =>{
                setBtnState(!btnState);
            }, 500);
        }
    }, [btnState])

    useEffect(() => {
        resetTimer();
        timeoutRef.current = setTimeout(() => {
            if(isShow === (sliderRef.current.childElementCount)) {
                setIsShow(1);
                var num = 1;
            } else {
                setIsShow(isShow + 1);
                num = isShow + 1;
            }
            sliderRef.current.style.left = -1 * window.innerWidth * (num - 1) + "px";
        }, 3000);

        return () => {
            resetTimer();
        }
    }, [isShow]);
    
    useEffect(() => {
        resetTimer2();
        timeoutRef2.current = setTimeout(() => {
            autoSlide("l");
        }, 3000);
        return () => {
            resetTimer2();
        }
    }, [workShowNum, cloneShowNum])

    useEffect(() => {
        if(VISIT_BEFORE === null) {
            setPopup(true)
        } else {
            if(VISIT_BEFORE === VISIT_NOW) {
                setPopup(false);
            } else {
                setPopup(true)
            }
        }
    }, [VISIT_BEFORE])

    // useEffect(() => {
    //     document.body.style.overflow = popup ? 'hidden' : 'auto';
    //     return () => {
    //         document.body.style.overflow = 'auto';
    //     };
    // }, [popup]);



    return(
        <div id={style.wrap}>
            {/* {popup && (
                <div id={style.modalBackdrop}>
                    {popup && <Popup setPopup={setPopup} popup={popup}></Popup>}
                </div>
            )} */}
            <SEOMetaTag title="한주에이알티 주식회사" description="한주에이알티 주식회사 메인페이지" url="https://hanjooart.com"></SEOMetaTag>
            <div className={style.mainContainer4}>
                <div className={style.containerSpan}>
                    <span>Work</span>
                    <div className={style.slideBtnContainer}>
                        <img id="sleft" src={left} alt="slide-left" onClick={changeSlide2}></img>
                        <img id="sright" src={right} alt="slide-right" onClick={changeSlide2}></img>
                    </div>
                </div>
                <div className={style.workSlideWrap}>
                    <div ref={workslideRef} className={style.workSlideContainer}>
                        {
                            workSlide !== null &&
                            workSlide.map((item) => (
                                <NavLink to="video?column=work" key={item.id} className={style.workSlideItem}>
                                    <img src={item.img} alt={item.e_theme}></img>
                                    <div>
                                        <span>{item.year} {item.broadcaster}</span>
                                        <span>{isEn ? item.e_theme : item.theme}</span>
                                    </div>
                                </NavLink>
                            ))
                        }
                    </div>
                    <div ref={workCloneRef} className={style.workSlideContainer}>
                        {
                            workSlide !== null &&
                            workSlide.map((item) => (
                                <NavLink to="video?column=work" key={item.id} className={style.workSlideItem}>
                                    <img src={item.img} alt={item.e_theme}></img>
                                    <div>
                                        <span>{item.year} {item.broadcaster}</span>
                                        <span>{isEn ? item.e_theme : item.theme}</span>
                                    </div>
                                </NavLink>
                            ))
                        }
                    </div>
                </div>
            </div>
            <div id={style.homeSlideWrap}>
                <div ref={sliderRef} id={style.slideContainer}>
                    {
                        getData !== undefined &&
                        getData.main.map((data) => (
                            <div key={data.id} className={style.slideItem} style={{backgroundImage: `url(${data.img})`}}>
                            </div>
                        ))
                    }
                </div>
                <div id={style.slideText}>
                    Where Imagination Becomes Reality
                </div>
                <div id={style.slideNumbering}>
                    {
                        getData !== undefined &&
                        getData.main.map((data, idx) => (
                            <div key={data.id} id={idx + 1} className={(idx+1) === isShow ? `${style.numberItem} ${style.show}` : style.numberItem} onClick={changeSlide}>
                                <hr></hr>
                            </div>
                        ))
                    }
                </div>
                {
                    admin &&
                    <div className={style.adminContainer}>
                        <button className={style.adminBtn} onClick={() => {setShowAdmin(true); setAdminField('main')}}>메인이미지 관리</button>
                    </div>
                }
            </div>
            <div className={style.mainContainer}>
                <img src={`${isEn ? mainLogoE : mainLogo}`} alt="main-logo"></img>
                <div className={style.mainContainerIntro}>
                    <span>{isEn ? "HANJOO A.R.T. presents diverse media content, encompassing production to performances." : "한주에이알티 주식회사는 제작부터 공연까지 다양한 사업 영역을 기반으로 종합 미디어 콘텐츠를 선보입니다."}</span>
                    <span>{isEn ? "By creating a media value chain, we drive growth in South Korea’s media industry and expand our ecosystem." : "미디어 밸류 체인의 구축으로 자체 생태계를 확장하고 대한민국 미디어 산업의 성장을 선도합니다."}</span>
                </div>
                <hr></hr>
                <div className={style.mainContainerIntro}>
                    <span>{isEn ? "HANJOO A.R.T.’ dream becomes reality soon, inspiring a fresh breeze of hope.": "한주에이알티 주식회사의 꿈은 곧 현실이 됩니다.\n우리의 꿈과 희망은 새로운 바람을 일으킵니다."}</span>
                    <span>{isEn ? "We promise to empower the globalization of K-content through innovative new content." : "새로운 콘텐츠를 통해서 K-content의 글로벌화에 힘을 실어 드릴 것을 약속드립니다."}</span>
                </div>
                <NavLink to="/about?column=outline">
                    More
                </NavLink>
            </div>
            <div className={style.mainContainer2}>
                {
                    mainData.map((data) => (
                        <NavLink to={data.link} key={data.id} className={style.container2Item}>
                            <img src={data.img} alt="con-2-image"></img>
                            <img src={data.img_m} alt="con-2-image-m"></img>
                            <div className={style.container2ItemContent}>
                                <span>{isEn ? data.eTheme : data.theme}</span>
                                <span>{isEn ? data.eContent : data.content}</span>
                            </div>
                            <div className={style.container2ItemHr}>
                                <div></div>
                                <hr></hr>
                            </div>
                        </NavLink>
                    ))
                }
            </div>
            <div className={style.mainContainer3}>
                <hr className={style.mainContainer3Hr}></hr>
                <img src={`${isEn ? mainLogoE2 : mainLogo2}`} alt="main-logo-2" id={style.mainContainer3Logo}></img>
                <div className={style.mainContainer3Info}>
                    {
                        infoData.map((data) =>(
                            <div key={data.id} className={style.infoItem}>
                                {data.content}
                            </div>
                        ))
                    }
                </div>
            </div>
            <Portal>
                {
                    showAdmin &&
                    <AdminModal setRender={ setRender } admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField}></AdminModal>
                }
            </Portal>
        </div>
    )
}