// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://use.typekit.net/cxz6nkg.css);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,900&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "* {\n  font-family: 'Pretendard';\n  box-sizing: border-box;\n}\n\na {\n  text-decoration: none;\n}\n\nhtml, body {\n  width: 100%;\n  @media only screen and (min-width: 1300px) {\n    min-width: 1300px;\n    min-height: 100vh;\n  }\n}\n\n#root, .wrapper {\n  width: 100%;\n  position: relative;\n  @media only screen and (min-width: 1300px) {\n    min-width: 1300px;\n  }\n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAIA;EACE,yBAAyB;EACzB,sBAAsB;AACxB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;EACX;IACE,iBAAiB;IACjB,iBAAiB;EACnB;AACF;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB;IACE,iBAAiB;EACnB;AACF","sourcesContent":["@import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');\n@import url('https://use.typekit.net/cxz6nkg.css');\n@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@1,900&display=swap');\n\n* {\n  font-family: 'Pretendard';\n  box-sizing: border-box;\n}\n\na {\n  text-decoration: none;\n}\n\nhtml, body {\n  width: 100%;\n  @media only screen and (min-width: 1300px) {\n    min-width: 1300px;\n    min-height: 100vh;\n  }\n}\n\n#root, .wrapper {\n  width: 100%;\n  position: relative;\n  @media only screen and (min-width: 1300px) {\n    min-width: 1300px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
