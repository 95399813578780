import style from "../css/Admin.module.css";
import { useEffect } from "react";
import AdminLogin from "./AdminLogin";
import AdminMain from "./AdminMain";
import AdminAbout from "./AdminAbout";
import AdminShow from "./AdminShow";
import AdminVideo from "./AdminVideo";
import AdminPr from "./AdminPr";
import AdminDetail from "./AdminDetail";

export default function AdminModal({admin, setAdmin, showAdmin, setShowAdmin, adminField, setAdminField, render, setRender}) {
    const components = {
        login: AdminLogin,
        main: AdminMain,
        about: AdminAbout,
        show: AdminShow,
        video: AdminVideo,
        pr: AdminPr,
        detail: AdminDetail
    }
    const ActiveComponent = components[adminField];

    useEffect(() => {
        document.body.style = `overflow: hidden`;
        return () => document.body.style = `overflow: auto`
    }, [])

    return(
        <div className={ style.wrap }>
            <ActiveComponent render={render} admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField} setRender={setRender}/>
        </div>
    )
}