import { useSearchParams } from "react-router-dom"
import style from "../css/SfSteel.module.css"
import { useCallback, useEffect, useRef, useState } from "react";
import SEOMetaTag from "../Components/SEOMetaTag";
import scrabImgBg1 from "../image/sfsteel/sf_scrab_1_bg.png"
import scrabImgBg2 from "../image/sfsteel/sf_scrab_2_bg.png"
import scrabImgBg3 from "../image/sfsteel/sf_scrab_3_bg.png"
import scrabImgBg4 from "../image/sfsteel/sf_scrab_4_bg.png"
import scrabImgBg5 from "../image/sfsteel/sf_scrab_5_bg.png"
import scrabImg1 from "../image/sfsteel/sf_scrab_1.png"
import scrabImg2 from "../image/sfsteel/sf_scrab_2.png"
import scrabImg3 from "../image/sfsteel/sf_scrab_3.png"
import scrabImg4 from "../image/sfsteel/sf_scrab_4.png"
import scrabImg5 from "../image/sfsteel/sf_scrab_5.png"
import scrabImgEn1 from "../image/sfsteel/sf_scrab_1_en.png"
import scrabImgEn5 from "../image/sfsteel/sf_scrab_5_en.png"
import salesImg1 from "../image/sfsteel/sales/seah.png"
import salesImg2 from "../image/sfsteel/sales/daehan.png"
import salesImg3 from "../image/sfsteel/sales/dk.png"
import salesImg4 from "../image/sfsteel/sales/hks.png"
import salesImg5 from "../image/sfsteel/sales/hy.png"
import salesImg6 from "../image/sfsteel/sales/hyundai.png"
import salesImg7 from "../image/sfsteel/sales/kisco.png"
import salesImg8 from "../image/sfsteel/sales/posco.png"
import salesImg9 from "../image/sfsteel/sales/yk.png"
import salesDocImg1 from "../image/sfsteel/sales/doc1.png"
import salesDocImg2 from "../image/sfsteel/sales/doc2.png"
import organizationImgT from "../image/sfsteel/organization_top.svg"
import organizationImgB from "../image/sfsteel/organization_bottom.svg"
import organizationImgTE from "../image/sfsteel/organization_top_en.svg"
import organizationImgBE from "../image/sfsteel/organization_bottom_en.svg"

export default function Sfsteel({isEn, isAdmin}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const circleRef = useRef();
    const hrRef = useRef();
    const contentRef = useRef();
    const columnData = ["eoutline", "scrab", "sales", "organization"];
    const columnKorData = ["회사개요", "스크랩 사업소개", "주요 매출처", "조직도"];
    const columnEnData = ["Overview", "About scrap", "Partners", "Organization"];
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    const [autoHeight, setAutoHeight] = useState([0, 0, 0, 0]);

    function changeColumn(column) {
        searchParams.set("column", column)
        setSearchParams(searchParams)
    }

    useEffect(() => {
        setColumn(searchParams.get("column"));
        t()
        switch (columnData.indexOf(searchParams.get("column"))) {
            case 0:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + 16 + "px";
                return
            case 1:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-100%)";
                contentRef.current.parentNode.style.height = autoHeight[1] + "px";
                return
            case 2:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[2].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[2].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[2].getBoundingClientRect().x + hrRef.current.children[2].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-200%)";
                contentRef.current.parentNode.style.height = autoHeight[2] + 80 + "px";
                return
            case 3:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.clientWidth - 16 + "px";
                } else {
                    circleRef.current.style.left = hrRef.current.clientWidth + 12 + "px";
                }
                contentRef.current.style.transform = "translateX(-300%)";
                contentRef.current.parentNode.style.height = autoHeight[3] + "px";
                return
            default:
                setColumn("eoutline");
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                contentRef.current.parentNode.style.height = autoHeight[0] + 16 + "px";
                return
        }
    }, [searchParams, screenWidth, autoHeight])

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(document.body.clientWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(document.body.clientWidth);
            });
        }
    }, [])

    function t(e) {
        if(JSON.stringify(autoHeight) !== JSON.stringify([
            contentRef.current.childNodes[0].clientHeight,
            contentRef.current.childNodes[1].clientHeight,
            contentRef.current.childNodes[2].clientHeight,
            contentRef.current.childNodes[3].clientHeight
        ])) {
            setAutoHeight([
                contentRef.current.childNodes[0].clientHeight,
                contentRef.current.childNodes[1].clientHeight,
                contentRef.current.childNodes[2].clientHeight,
                contentRef.current.childNodes[3].clientHeight
            ]);
        }
    }
    
    return(
        <div className={style.wrap} onLoad={t}>
            <SEOMetaTag title="SF Steel" description="한주에이알티 주식회사 SF Steel 페이지" url="https://hanjooart.com/Sfsteel"></SEOMetaTag>
            <div className={style.topContainer}>
                <span>{isEn ? "SF Steel" : "SF Steel"}</span>
                <div ref={hrRef} className={style.columnContainer}>
                    {
                        columnData.map((data, idx) => (
                            <span key={idx} onClick={() => changeColumn(data)} className={data === column ? `${style.columnContainerItem} ${style.selected}` : style.columnContainerItem}>{isEn ? columnEnData[idx] : columnKorData[idx]}</span>
                        ))
                    }
                </div>
                <div ref={circleRef} className={style.hrCircle}></div>
            </div>
            <div className={style.bottomContainer}>
                <div ref={contentRef} className={style.bottomContainerWrap}>
                    <div key={Math.random()} className={style.contentContainer}>
                        <div className={style.overviewItem}>
                            <span className={style.overviewTheme}>Company</span>
                            <span className={style.overviewContent}>{isEn ? "SF Steel Co., Ltd." : "(주)에스에프스틸"}</span>
                        </div>
                        <div className={style.overviewItem}>
                            <span className={style.overviewTheme}>CEO</span>
                            <span className={style.overviewContent}>{isEn ? "Jeonggi Lee" : "이정기"}</span>
                        </div>
                        <div className={style.overviewItem}>
                            <span className={style.overviewTheme}>Business</span>
                            <span className={style.overviewContent}>{isEn ? "Purchase, sale and processing of iron scrap" : "철스크랩 매입 및 판매,가공"}</span>
                        </div>
                        <div className={style.overviewItem}>
                            <span className={style.overviewTheme}>Since</span>
                            <span className={style.overviewContent}>{isEn ? "January 14, 2019" : "2019년 1월 14일"}</span>
                        </div>
                        <div className={style.overviewItem}>
                            <span className={style.overviewTheme}>Address</span>
                            <span className={style.overviewContent}>{isEn ? "313, Central-ro, Yeonsu-gu, Incheon, Republic of Korea" : "인천광역시 연수구 센트럴로 313 송도씨워크인테라스한라 C동 1322호"}</span>
                        </div>
                        <div className={style.overviewItem}>
                            <span className={style.overviewTheme}>Contact</span>
                            <span className={style.overviewContent}>{"Tel: 032-815-3577\nFax: 032-815-3590"}</span>
                        </div>
                    </div>
                    <div key={Math.random()} className={style.contentContainer}>
                        <div className={style.scrabItem}>
                            <img src={scrabImgBg1} alt="scrab-img-bg-1"></img>
                            <div className={style.scrabContent}>
                                <img className={style.scrabOnImg} src={isEn ? scrabImgEn1 : scrabImg1} alt="scrab-img-1"></img>
                            </div>
                        </div>
                        <div className={style.scrabItem}>
                            <img src={scrabImgBg2} alt="scrab-img-bg-2"></img>
                            <div className={style.scrabContainer}>
                                <div className={style.scrabContent}>
                                    <span>
                                        {
                                            isEn ?
                                            <>
                                            A circulating resource that<br />can be
                                            <font color='#E33C0B !important'> infinitely regenerated</font>
                                            </>
                                            :
                                            <>
                                            <font color='#E33C0B !important'>무한재생</font>
                                            이 가능한 순환자원
                                            </>
                                        }
                                    </span>
                                    <span>
                                        {
                                            isEn ?
                                            "Infinite recycling of production → consumption → collection → reproduction"
                                            :
                                            "생산 → 소비 → 회수 → 재생산의 무한 리싸이클링"
                                        }
                                    </span>
                                    <span>
                                        {
                                            isEn ?
                                            "According to the Act on Electricity and Electronics and Automobile Resource Circulation 95% target recycling of raw materials for home appliances and automobiles"
                                            :
                                            "전기전자 및 자동차 자원순환에 관한 법률에 의해 가전제품, 자동차 원자재 재활용율 95% 목표"
                                        }
                                    </span>
                                </div>
                                <img src={scrabImg2} alt="scrab-img-2"></img>
                            </div>
                        </div>
                        <div className={style.scrabItem}>
                            <img src={scrabImgBg3} alt="scrab-img-bg-3"></img>
                            <div className={style.scrabContainer}>
                                <img src={scrabImg3} alt="scrab-img-3"></img>
                                <div className={style.scrabContent}>
                                    <span>
                                        {
                                            isEn ?
                                            <>
                                            CO2 waste emission
                                            <font color='#E33C0B !important'> reduction</font>
                                            </>
                                            :
                                            <>
                                            CO2 폐기물
                                            <font color='#E33C0B !important'> 배출 감축</font>
                                            </>
                                        }
                                    </span>
                                    <span>
                                        {
                                            isEn ?
                                            "Infinite recycling of production → consLow HMR (molten iron usage ratio) using iron scrap\nCO2 reduction through operationumption → collection → reproduction"
                                            :
                                            "철스크랩을 활용한 低HMR(용선사용비율)\n조업으로 이산화탄소 저감"
                                        }
                                    </span>
                                    <span>
                                        {
                                            isEn ?
                                            "60% CO2 emissions compared to iron ore\nWaste emissions reduced by 80%"
                                            :
                                            "철광성에 비해 CO2 배출량 60%\n폐기물 배출은 80% 감소"
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className={style.scrabItem}>
                            <div className={style.scrabContainer}>
                                <div className={style.scrabContent}>
                                    <span>
                                        {
                                            isEn ?
                                            <>
                                            All other steel raw materials are imported,<br />Steel scrap is supplied 
                                            <font color='#E33C0B !important'> domestically</font>
                                            </>
                                            :
                                            <>
                                            타 제철원료는 전량 수입,<br />철스크랩은
                                            <font color='#E33C0B !important'> 국내 공급</font>
                                            </>
                                        }
                                    </span>
                                    <span>
                                        {
                                            isEn ?
                                            "Domestic self-sufficiency rate is on the rise, and steel stocks are increasing"
                                            :
                                            "국내 자급률 상승중이며, 철강 축적량 중가"
                                        }
                                    </span>
                                    <span>
                                        {
                                            isEn ?
                                            "Therefore, 60% of CO2 emission compared to iron ore,\nWaste emissions reduced by 80%"
                                            :
                                            "따라서 철광성에 대비CO2 배출량 60%, 폐기물 배출은 80% 감소"
                                        }
                                    </span>
                                </div>
                                <img src={scrabImg4} alt="scrab-img-4"></img>
                            </div>
                            <img src={scrabImgBg4} alt="scrab-img-bg-4"></img>
                        </div>
                        <div className={style.scrabItem}>
                            <img src={scrabImgBg5} alt="scrab-img-bg-5"></img>
                            <div className={style.scrabContent}>
                                <img className={style.scrabOnImg2} src={isEn ? scrabImgEn5 : scrabImg5} alt="scrab-img-5"></img>
                            </div>
                        </div>
                    </div>
                    <div key={Math.random()} className={style.contentContainer}>
                        <div className={style.salesItem}>
                            <span>{isEn ? "· SeAH Besteel" : "· 세아베스틸"}</span>
                            <div className={style.salesContent}>
                                <img className={style.salesImg} src={salesImg1} alt="seah-img"></img>
                                <div className={style.salesHr}></div>
                                <div className={style.salesDocImg}>
                                    <img src={salesDocImg1} alt="doc1-img"></img>
                                    <img src={salesDocImg2} alt="doc2-img"></img>
                                </div>
                            </div>
                        </div>
                        <div className={style.salesItem}>
                            <span>{isEn ? "· Other Sales Sources" : "· 기타 매출처"}</span>
                            <div className={style.salesContent}>
                                <img src={salesImg2} alt="sales-img2"></img>
                                <img src={salesImg3} alt="sales-img3"></img>
                                <img src={salesImg4} alt="sales-img4"></img>
                                <img src={salesImg5} alt="sales-img5"></img>
                                <img src={salesImg6} alt="sales-img6"></img>
                                <img src={salesImg7} alt="sales-img7"></img>
                                <img src={salesImg8} alt="sales-img8"></img>
                                <img src={salesImg9} alt="sales-img9"></img>
                            </div>
                        </div>
                    </div>
                    <div key={Math.random()} className={style.contentContainer}>
                        <div className={style.orgItem}>
                            <img src={isEn ? organizationImgTE : organizationImgT} alt="organization-img-top"></img>
                        </div>
                        <div className={style.orgItem}>
                            <img src={isEn ? organizationImgBE : organizationImgB} alt="organization-img-bottom"></img>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}