import style from "../css/Detail.module.css";
import { useLocation, useSearchParams, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import downloadIcon from "../image/component/download_icon.svg"
import SEOMetaTag from "../Components/SEOMetaTag";
import axios from "axios";
import Portal from '../Components/Portal';
import AdminModal from '../AdminComponents/AdminModal';

export default function Detail({isEn, admin, setAdmin, showAdmin, setShowAdmin, adminField, setAdminField}) {
    const [searchParams, setSearchParams] = useSearchParams();
    const [column, setColumn] = useState(searchParams.get("column"));
    const [getData, setData] = useState([]);
    const circleRef = useRef();
    const contentRef = useRef();
    const hrRef = useRef();
    const columnData = ["notice", "advertisement", "press", "disclosure", "policy"];
    const columnKorData = ["공지사항", "언론 및 광고", "보도자료", "공시자료", "회사규정"];
    const columnEnData = ["Notice", "Advertisement", "Press", "Disclosure", "Policy"];
    const [imgWidth, setImgWidth] = useState();
    const location = useLocation();
    const { state } = location;
    const navigate = useNavigate();
    const [screenWidth, setScreenWidth] = useState(document.body.clientWidth);
    
    function moveColumn(data) {
        if(data !== column) {
            navigate("/pr?column=" + data);
        }
    }

    useEffect(() => {
        setColumn(searchParams.get("column"));
        switch (columnData.indexOf(searchParams.get("column"))) {
            case 0:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                return
            case 1:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[1].getBoundingClientRect().x + hrRef.current.children[1].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-100%)";
                return
            case 2:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[2].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[2].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[2].getBoundingClientRect().x + hrRef.current.children[2].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-200%)";
                return
            case 3:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.children[3].getBoundingClientRect().x - hrRef.current.getBoundingClientRect().x + hrRef.current.children[3].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                } else {
                    circleRef.current.style.left = hrRef.current.children[3].getBoundingClientRect().x + hrRef.current.children[3].clientWidth / 2 - circleRef.current.clientWidth / 2 + "px"
                }
                contentRef.current.style.transform = "translateX(-300%)";
                return
            case 4:
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = hrRef.current.clientWidth + "px";
                } else {
                    circleRef.current.style.left = hrRef.current.clientWidth + "px";
                }
                contentRef.current.style.transform = "translateX(-400%)";
                return
            default:
                setColumn("notice");
                if(document.body.clientWidth > 1317) {
                    circleRef.current.style.left = "0";
                } else {
                    circleRef.current.style.left = "20px";
                }
                contentRef.current.style.transform = "translateX(0)";
                return
        }
    }, [searchParams, screenWidth])
    
    useEffect(() => {
        if(window.innerWidth > 768) {
            setImgWidth(true)
        } else {
            setImgWidth(false)
        }
    }, [window.innerWidth])

    async function apiCall() {
        try {
            const response = await axios.get(process.env.REACT_APP_SERVER +'/api/data/detail/' + column + '/' + searchParams.get("postId"));
            setData(response.data.data[0])
        } catch(err) {
            console.log("ERROR: ", err);
        }
    }

    async function apiCallDelete() {
        if(window.confirm('정말 삭제하시겠습니까?'))
        {
            try {
                const response = await axios.delete(process.env.REACT_APP_SERVER + "/api/admin/delete/pr", {
                    data: {
                        id: getData.id,
                        column: column,
                        img: (column !== "disclosure") ? (column === "notice") ? [getData.imgurl, getData.m_imgurl] : [getData.img] : [''],
                        file: column === "notice" ? getData.file : []
                    }
                });
                if(response.data.state === "success") {
                    alert('삭제가 완료되었습니다.');
                    window.location.replace("../pr?column=" + column)
                } else {
                    if(response.data.errmsg !== undefined) {
                        alert(response.data.errmsg)
                    } else {
                        alert('삭제에 실패하였습니다.')
                    }
                }
            } catch(err) {
                console.log("ERROR: ", err);
            }
        }
    }

    useEffect(() => {
        window.addEventListener('resize', () => {
            setScreenWidth(document.body.clientWidth);
        });
        return () => {
            window.removeEventListener('resize', () => {
                setScreenWidth(document.body.clientWidth);
            });
        }
    }, [])

    useEffect(() => {
        apiCall()
    }, [])

    return(
        <div className={style.wrap}>
            <div className={style.topContainer}>
                <SEOMetaTag title="PR 상세" description="파트너스 PR 상세 페이지" url="https://hanjooart.com/pr/detail"></SEOMetaTag>
                <span>PR</span>
                <div ref={hrRef} className={style.columnContainer}>
                    {
                        columnData.map((data, idx) => (
                            <span key={idx} onClick={() => moveColumn(data)} className={data === column ? `${style.columnContainerItem} ${style.selected}` : style.columnContainerItem}>{isEn ? columnEnData[idx] : columnKorData[idx]}</span>
                        ))
                    }
                </div>
                <div ref={circleRef} className={style.hrCircle}></div>
            </div>
            <div className={style.bottomContainer}>
                <div ref={contentRef} className={style.bottomContainerWrap}>
                    <div className={style.contentContainer}>
                        <div className={style.detailHead}>
                            <span className={style.headDate}>{isEn ? getData.e_date : getData.date}</span>
                            <span className={style.headTheme}>{isEn ? getData.e_theme : getData.theme}</span>
                        </div>
                        {
                            ((column === "press") || (column === "advertisement")) ?
                                <div className={style.detailBody}>
                                    {
                                        getData.img &&
                                        <img src={getData.img} className={style.bodyImg} alt="detail-img"></img>
                                    }
                                    <div className={style.bodyContent}>
                                        {isEn ? getData.e_content : getData.content}
                                    </div>
                                </div>
                            :
                            column === "notice" ?
                            <div className={style.detailBody}>
                                <div className={style.bodyContent}>
                                    {
                                        imgWidth ?
                                            getData.imgurl &&
                                            <img src={getData.imgurl} className={style.bodyImg} alt="detail-img"></img>
                                        :
                                            getData.m_imgurl &&
                                            <img src={getData.m_imgurl} className={style.bodyImg} alt="detail-img"></img>
                                    }
                                    {isEn ? getData.e_content : getData.content}
                                </div>
                                {
                                    getData.length !== 0 &&
                                    <div className={style.bodyFile}>
                                        {
                                            getData.file.map((data, idx) => (            
                                                <a href={data} key={idx} className={style.downloadBtn} download="">
                                                    <img src={downloadIcon} alt="download-icon"></img>
                                                    <span>{data.split('/file/')[1]}</span>
                                                </a>
                                            ))
                                        }
                                    </div>
                                }
                            </div>
                            :
                                <div className={style.detailBody}>
                                    <div className={style.bodyContent}>
                                        {isEn ? getData.e_content : getData.content}
                                    </div>
                                    {
                                        getData.length !== 0 &&
                                        <div className={style.bodyFile}>
                                            {
                                                getData.file.map((data, idx) => (            
                                                    <a href={data} key={idx} className={style.downloadBtn} download="">
                                                        <img src={downloadIcon} alt="download-icon"></img>
                                                        <span>{data.split('/file/')[1]}</span>
                                                    </a>
                                                ))
                                            }
                                        </div>
                                    }
                                </div>
                        }
                        <div className={style.detailTail}>
                            <button onClick={() => navigate(-1)}>{isEn ? "List" : "목록으로"}</button>
                        </div>
                    </div>
                </div>
            </div>
            {
                admin &&
                <div className={style.adminContainer}>
                    <button className={style.adminBtn} onClick={() => apiCallDelete()}>{columnKorData[columnData.indexOf(column)]} 삭제</button>
                    <button className={style.adminBtn} onClick={() => {setShowAdmin(true); setAdminField('detail')}}>{columnKorData[columnData.indexOf(column)]} 수정</button>
                </div>
            }
            <Portal>
                {
                    showAdmin &&
                    <AdminModal admin={admin} setAdmin={setAdmin} showAdmin={showAdmin} setShowAdmin={setShowAdmin} adminField={adminField} setAdminField={setAdminField}></AdminModal>
                }
            </Portal>
        </div>
    )
}