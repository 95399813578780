import { useEffect } from "react";
import { Link } from "react-router-dom";

import style from "../css/Popup.module.css";
import exit from "../image/popup/exit.svg"
import logo from "../image/component/header_logo.svg";

export default function Popup({setPopup, popup}) {
    function closeToday() {
        const expire = new Date();
        const expireDate = expire.getDate();

        localStorage.setItem('VisitCookie', expireDate);
        setPopup(false);
    }

    return(
        <div className={style.popupContainer}>
            <div className={style.popupHeader}>
                <img src={exit} alt="popup-exit" onClick={() => setPopup(false)}></img>
            </div>
            <div className={style.popupBody}>
                <div className={style.popupCnt}>
                    {/*<img src={logo} alt="logo" id={style.bodyLogo} />*/}
                    <p>주주총회 모집 공고</p>
                    <div className={style.popupTxt}>
                        주주제위<br/><br/>
                        주주님의 건승과 댁내의 평안을 기원합니다.<br/>
                        당사는 상법 제363조와 정관 제 25조에 의거 제 47기 임시주주총회를 아래와 같이 개최하오니 참석하여 주시기 바랍니다.<br/>
                        <span>- 아&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;래 -</span>
                    </div>
                    <div className={style.popupInfo}>
                        <div>1. 일 시 : 2024년 9월 20일 (금) 오전9시</div>
                        <div>2. 장 소 : 서울시 송파구 충민로 10, 10층 대회의실(문정동, 가든파이브툴)</div>
                        <div>
                            <span>3. 회의목적사항</span>
                            <span>
                                가. 보고사항 : 감사의 감사보고<br/>
                                나. 부의안건<br/>
                                <span>
                                    제1호 의안 : 자본잉여금의 이익잉여금 전입의 건<br/>
                                    제2호 의안 : 이사 선임의 건<br/>
                                    제2-1호 의안 : 사내이사 윤정호 선임의 건<br/>
                                    제2-2호 의안 : 사내이사 조재규 선임의 건<br/>
                                    제2-3호 의안 : 사외이사 백창현 선임의 건<br/>
                                </span>
                            </span>
                        </div>

                        <div>
                            <span>4. 경영참고사항 비치</span>
                            <span>상법 제542조의4에 의한 경영참고사항은 당사 인터넷 홈페이지에 게재하고 본점과 명의개서대행회사(국민은행 증권대행부)에 비치하였으며, 금융위원회 및 한국거래소에 전자공시하여 조회가 가능하오니 참고하시기 바랍니다.</span>
                        </div>

                        <div>
                            <span>5. 실질주주의 의결권행사에 관한 사항</span>
                            <span>금번 우리회사의 주주총회에는 자본시장과 금융투자업에 관한 법률 제314조 제5항 단서 규정에 의거하여 한국예탁결제원이 주주님들의 의결권을 행사할 수 없습니다. 따라서 주주님들께서는 한국예탁결제원에 의결권행사에 관한 의사표시를 하실 필요가 없으며, 종전과 같이 주주총회 참석하여 의결권을 직접행사 하시거나 또는 위임장에 의거 의결권을 간접행사 하실 수 있습니다.</span>
                        </div>

                        <div>
                            <span>6. 전자투표 및 전자위임장권유에 관한 사항</span>
                            <span>우리회사는「상법」제368조의 4에 따른 전자투표제도와 「자본시장과 금융투자업에 관한 법률 시행령」 제 160조 제 5호에 따른 전자위임장권유제도를 이번 주주총회에서 활용하기로 결의하였고, 이 두 제도의 관리업무를 한국예탁결제원에 위탁하였습니다. 주주님들께서는 아래에서 정한 방법에 따라 주주총회에 참석하지 아니하고 전자투표방식으로 의결권을 행사하시거나, 전자위임장을 수여하실 수 있습니다</span>
                            <span>
                                가. 전자투표,전자위임장권유관리시스템 인터넷 주소 :
                                <span>
                                    - PC : https://evote.ksd.or.kr<br/>
                                    - 모바일 : https://evote.ksd.or.kr/m<br/>
                                </span>
                            </span>
                            <span>
                                나. 전자투표 전자위임장 수여기간:
                                <span>
                                    - 2024년 9월 10일 09시 ~ 2024년 9월 19일 17시<br/>
                                    - 기간 중 24시간 이용 가능
                                </span>
                            </span>
                            <span>
                                다. 시스템에 공인인증을 통해 주주본인을 확인 후 의안 별 의결권 행사 또는
                                <span>
                                    전자위임장 수여<br/>
                                    - 주주확인용 공인인증서의 종류: 공동인증서 및 민간인증서 (k-vote에서 사용가능한 인증서 한정)
                                </span>
                            </span>
                            <span>라. 수정동의안 처리 : 주주총회에서 상정된 의안에 관하여 수정동의가 제출되는 경우 기권으로 처리</span>
                        </div>

                        <div>
                            <span> 7. 주주총회 참석 시 준비물</span>
                            <span>
                            - 직접행사 : 신분증<br/>
                            - 대리행사 : 위임장(주주와 대리인 인적사항 기재, 인감날인 또는 서명),
                            대리인 신분증
                            </span>
                        </div>

                        <div>
                            <span>8. 기타사항</span>
                            <span>금번 주주총회에서는 참석주주님을 위한 기념품을 제공하지 아니하오니 이점 양지하시기 바랍니다.</span>
                        </div>
                    </div>
                    <div className={style.popupAdd}>
                        <span>한주에이알티 주식회사</span>
                        <span>대표이사 이 정 기</span>
                    </div>
                </div>
            </div>
            <div className={style.popupFooter}>
                <span onClick={closeToday}>오늘하루 그만보기</span>
            </div>
        </div>
    )
}